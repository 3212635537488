
import Float from '@/components/Float.vue';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue';
import { defineComponent, ref, VNode } from 'vue';
import AutoTeleport from './AutoTeleport.vue';

export default defineComponent({
  components: {
    Popover,
    PopoverButton,
    PopoverPanel,
    Float,
    AutoTeleport,
  },
  setup() {
    const panelRef = ref<VNode | null>(null);
    const elRef = ref<HTMLElement | null>(null);
    const buttonRef = ref<VNode | null>(null);

    return {
      panelRef,
      elRef,
      buttonRef,
    };
  },
});
