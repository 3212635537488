
import { defineComponent } from 'vue';
import BigChevron from './overview/BigChevron.vue';
import EarnNear from './overview/EarnNear.vue';
import BalanceWidget from './overview/widgets/BalanceWidget.vue';
import LevelWidget from './overview/widgets/LevelWidget.vue';
import RankWidget from './overview/widgets/RankWidget.vue';
import ScoreWidget from './overview/widgets/ScoreWidget.vue';
import StatsWidget from './overview/widgets/StatsWidget.vue';
import TransactionHistoryWidget from './overview/widgets/TransactionHistoryWidget.vue';
import TransactionRateWidget from './overview/widgets/TransactionRateWidget.vue';

export default defineComponent({
  components: {
    BigChevron,
    EarnNear,
    ScoreWidget,
    LevelWidget,
    RankWidget,
    BalanceWidget,
    StatsWidget,
    TransactionHistoryWidget,
    TransactionRateWidget,
  },
});
