<template>
  <svg
    fill="none"
    version="1.1"
    viewBox="0 0 37 41"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m19.929 0.36264c-0.8486-0.48352-1.8894-0.48352-2.738-1e-6l-15.436 8.7949c-0.86336 0.49191-1.3964 1.4092-1.3964 2.4029v17.527c0 0.9937 0.53309 1.911 1.3964 2.4029l15.436 8.7949c0.8486 0.4835 1.8894 0.4835 2.738 0l15.436-8.7949c0.8634-0.4919 1.3965-1.4092 1.3965-2.4029v-17.527c0-0.9937-0.5331-1.911-1.3965-2.4029l-15.436-8.7949zm3.1752 14.903h0.8988c0.4767 0 0.9339 0.1864 1.271 0.5184 0.3371 0.3319 0.5265 0.7821 0.5265 1.2515v8.8495c0 0.4694-0.1894 0.9196-0.5265 1.2515s-0.7943 0.5184-1.271 0.5184h-10.785c-0.4767 0-0.934-0.1865-1.2711-0.5184s-0.5265-0.7821-0.5265-1.2515v-8.8495c0-0.4694 0.1894-0.9196 0.5265-1.2515 0.3371-0.332 0.7944-0.5184 1.2711-0.5184h0.8988v-0.885c0-2.4398 2.016-4.4248 4.4939-4.4248s4.4939 1.985 4.4939 4.4248v0.885zm-4.4939-3.5398c-1.4866 0-2.6964 1.1911-2.6964 2.6548v0.885h5.3927v-0.885c0-1.4637-1.2097-2.6548-2.6963-2.6548zm0.8988 12.389v-2.015c0.5347-0.3071 0.8987-0.8717 0.8987-1.5248 0-0.4694-0.1893-0.9196-0.5264-1.2515-0.3372-0.332-0.7944-0.5184-1.2711-0.5184-0.4768 0-0.934 0.1864-1.2711 0.5184-0.3371 0.3319-0.5265 0.7821-0.5265 1.2515 0 0.654 0.364 1.2186 0.8988 1.5248v2.015h1.7976z"
      clip-rule="evenodd"
      fill="currentColor"
      fill-rule="evenodd"
    />
  </svg>
</template>
