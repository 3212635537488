<template>
  <svg viewBox="0 0 30 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.0001 16.1309L28.9986 8.06723L15.0001 0L1.00139 8.06723L15.0001 16.1309Z"
      fill="currentColor"
    />
    <path
      d="M13.9987 17.8624L0 9.79871V25.9328L13.9987 34V17.8624Z"
      fill="currentColor"
    />
    <path
      d="M16.0017 17.8624V34L30 25.9328V9.79871L16.0017 17.8624Z"
      fill="currentColor"
    />
  </svg>
</template>
