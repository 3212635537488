<template>
  <svg
    fill="none"
    version="1.1"
    viewBox="0 0 48 48"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m24 0c-13.255 0-24 10.745-24 24s10.745 24 24 24 24-10.745 24-24-10.745-24-24-24zm-12.307 14.428h23.506c1.6869 0 2.0547 0.46422 2.0547 1.7617l0.019532 0.091797s-12.396 8.984-13.807 9.5469c-1.3351-0.4845-13.826-9.3477-13.826-9.3477l-0.021484-0.19922c0-1.2967 0.33893-1.8535 2.0742-1.8535zm-2.0742 4.2754s12.645 9.0713 13.848 9.5195c1.2237-0.4695 13.807-9.5195 13.807-9.5195v12.826c-1e-6 1.8248-0.32572 2.1367-2.0742 2.1367h-23.506c-1.7076 0-2.0742-0.35542-2.0742-2.1367v-12.826z"
      fill="currentColor"
    />
  </svg>
</template>
