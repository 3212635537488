<template>
  <svg viewBox="0 0 36 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20.5 1.44337C18.9529 0.550208 17.047 0.550208 15.5 1.44337L3.17944 8.55662C1.63244 9.44978 0.679443 11.1004 0.679443 12.8867V27.1132C0.679443 28.8996 1.63244 30.5502 3.17944 31.4434L15.5 38.5566C17.047 39.4498 18.9529 39.4498 20.5 38.5566L32.8205 31.4434C34.3675 30.5502 35.3205 28.8996 35.3205 27.1132V12.8867C35.3205 11.1004 34.3675 9.44978 32.8205 8.55662L20.5 1.44337ZM16.875 20.714H8.99995L21 7L18.268 17.286H26.143L14.137 31L16.875 20.714Z"
      fill="currentColor"
    />
  </svg>
</template>
