import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-640903d9"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex-grow flex flex-col space-y-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BalanceHistoryLoader = _resolveComponent("BalanceHistoryLoader")!
  const _component_Chart = _resolveComponent("Chart")!
  const _component_DashboardCard = _resolveComponent("DashboardCard")!
  const _component_PieChartLoader = _resolveComponent("PieChartLoader")!
  const _component_HorizontalBarChartLoader = _resolveComponent("HorizontalBarChartLoader")!

  return (_openBlock(), _createElementBlock("main", _hoisted_1, [
    _createVNode(_component_DashboardCard, { title: "Balance History" }, {
      default: _withCtx(() => [
        (_ctx.viewsIsLoading || _ctx.actionsIsLoading)
          ? (_openBlock(), _createBlock(_component_BalanceHistoryLoader, {
              key: 0,
              class: "chart"
            }))
          : (_openBlock(), _createBlock(_component_Chart, {
              key: 1,
              class: "chart",
              option: _ctx.balanceHistoryOption,
              autoresize: ""
            }, null, 8, ["option"]))
      ]),
      _: 1
    }),
    _createVNode(_component_DashboardCard, { title: "Action Types" }, {
      default: _withCtx(() => [
        (_ctx.actionsIsLoading)
          ? (_openBlock(), _createBlock(_component_PieChartLoader, {
              key: 0,
              class: "chart"
            }))
          : (_openBlock(), _createBlock(_component_Chart, {
              key: 1,
              class: "chart",
              option: _ctx.actionTypeOption,
              autoresize: ""
            }, null, 8, ["option"]))
      ]),
      _: 1
    }),
    _createVNode(_component_DashboardCard, { title: "Top 10 Senders" }, {
      default: _withCtx(() => [
        (_ctx.actionsIsLoading)
          ? (_openBlock(), _createBlock(_component_HorizontalBarChartLoader, {
              key: 0,
              class: "chart"
            }))
          : (_openBlock(), _createBlock(_component_Chart, {
              key: 1,
              class: "chart",
              option: _ctx.topIncomingOption,
              autoresize: ""
            }, null, 8, ["option"]))
      ]),
      _: 1
    }),
    _createVNode(_component_DashboardCard, { title: "Top 10 Receivers" }, {
      default: _withCtx(() => [
        (_ctx.actionsIsLoading)
          ? (_openBlock(), _createBlock(_component_HorizontalBarChartLoader, {
              key: 0,
              class: "chart"
            }))
          : (_openBlock(), _createBlock(_component_Chart, {
              key: 1,
              class: "chart",
              option: _ctx.topOutgoingOption,
              autoresize: ""
            }, null, 8, ["option"]))
      ]),
      _: 1
    })
  ]))
}