import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_question_mark_icon = _resolveComponent("question-mark-icon")!
  const _component_Tooltip = _resolveComponent("Tooltip")!

  return (_openBlock(), _createBlock(_component_Tooltip, { class: "text-gray-400 text-opacity-80 cursor-pointer p-2 rounded-sm hover:text-opacity-100 hover:bg-gray-500 hover:bg-opacity-10" }, {
    trigger: _withCtx(() => [
      _createVNode(_component_question_mark_icon, { class: "w-4 h-4" })
    ]),
    content: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }))
}