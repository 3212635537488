import { withKeys as _withKeys, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("input", {
    value: _ctx.modelValue.toLowerCase(),
    onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event.target.value.toLowerCase()))),
    onKeydown: _cache[1] || (_cache[1] = _withKeys(($event: any) => (_ctx.$emit('submit', $event.target.value.toLowerCase())), ["enter"])),
    type: "text",
    class: _normalizeClass([[_ctx.modelValue.length ? 'bg-white' : 'bg-gray-100'], "text-xl text-black font-normal block w-full outline-none rounded-sm p-3 z-10"])
  }, null, 42, _hoisted_1))
}