<template>
  <div class="px-12 py-4 animate-pulse flex flex-col">
    <div class="flex">
      <div class="flex-shrink-0 mr-4 flex flex-col">
        <div class="bg-gray-200 rounded-md w-24 h-4 my-6"></div>
        <div class="bg-gray-200 rounded-md w-24 h-4 my-6"></div>
        <div class="bg-gray-200 rounded-md w-24 h-4 my-6"></div>
        <div class="bg-gray-200 rounded-md w-24 h-4 my-6"></div>
        <div class="bg-gray-200 rounded-md w-24 h-4 my-6"></div>
        <div class="bg-gray-200 rounded-md w-24 h-4 my-6"></div>
      </div>
      <div class="flex-1 flex flex-col justify-start">
        <div class="flex-1 bg-gray-200 rounded-md w-full h-12 my-2"></div>
        <div class="flex-1 bg-gray-200 rounded-md w-5/6 h-12 my-2"></div>
        <div class="flex-1 bg-gray-200 rounded-md w-3/5 h-12 my-2"></div>
        <div class="flex-1 bg-gray-200 rounded-md w-1/2 h-12 my-2"></div>
        <div class="flex-1 bg-gray-200 rounded-md w-1/4 h-12 my-2"></div>
        <div class="flex-1 bg-gray-200 rounded-md w-1/5 h-12 my-2"></div>
      </div>
    </div>
    <div class="flex justify-between ml-28 pt-3">
      <div class="bg-gray-200 rounded-md w-8 h-4"></div>
      <div class="bg-gray-200 rounded-md w-8 h-4"></div>
      <div class="bg-gray-200 rounded-md w-8 h-4"></div>
      <div class="bg-gray-200 rounded-md w-8 h-4"></div>
      <div class="bg-gray-200 rounded-md w-8 h-4"></div>
      <div class="bg-gray-200 rounded-md w-8 h-4"></div>
    </div>
  </div>
</template>
