
import Modal from '@/components/Modal.vue';
import { useNear } from '@/composables/useNear';
import { useScore } from '@/composables/useScore';
import { useTransactionActions } from '@/composables/useTransactionActions';
import { networks } from '@/services/near/indexer/networks';
import {
  ActionKind,
  UnifiedTransactionAction,
} from '@/services/near/indexer/types';
import { getActionScore } from '@/utils/score';
import { DateTime } from 'luxon';
import { defineComponent, ref, watch } from 'vue';
import DashboardCard from '../DashboardCard.vue';
import HeaderListButtonTemplate from './HeaderListButtonTemplate.vue';

export default defineComponent({
  components: {
    DashboardCard,
    HeaderListButtonTemplate,
    Modal,
  },
  setup() {
    const { account, network, timeframe } = useNear();
    const { score, isLoading: isScoreLoading } = useScore({
      account,
      network,
      timeframe,
    });
    const { actions, isLoading: isActionsLoading } = useTransactionActions({
      account,
      network,
      timeframe,
    });

    const scoringActions = ref(
      [] as {
        actionKind: ActionKind;
        score: number;
        action: UnifiedTransactionAction;
      }[],
    );

    watch([actions, account], ([actions, account]) => {
      scoringActions.value = actions
        .map(action => ({
          actionKind: action.action_kind,
          score: getActionScore(action, account),
          action,
        }))
        .filter(a => a.score > 0);
    });

    const helpModalOpen = ref(false);

    const scoreTable: {
      name: string;
      points: number;
    }[] = [
      {
        name: 'Outgoing Transfer',
        points: 10,
      },
      {
        name: 'Incoming Transfer',
        points: 2,
      },
      {
        name: 'Function Call',
        points: 10,
      },
      {
        name: 'Contract Deployment',
        points: 100,
      },
      {
        name: 'Account Creation',
        points: 50,
      },
    ];

    const viewModalOpen = ref(false);

    return {
      getActionScore,
      account,
      score,
      isScoreLoading,
      actions,
      isActionsLoading,
      scoringActions,
      helpModalOpen,
      scoreTable,
      viewModalOpen,
      DateTime,
      network,
      networks,
    };
  },
});
