import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TopBar = _resolveComponent("TopBar")!
  const _component_IntakeHero = _resolveComponent("IntakeHero")!
  const _component_Overview = _resolveComponent("Overview")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_TopBar),
    _createVNode(_component_IntakeHero),
    _createVNode(_component_Overview),
    _createVNode(_component_IntakeHero),
    _createVNode(_component_Footer)
  ], 64))
}