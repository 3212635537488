import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8ab1ca8c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex-grow flex flex-col space-y-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LeaderboardTableCard = _resolveComponent("LeaderboardTableCard")!

  return (_openBlock(), _createElementBlock("main", _hoisted_1, [
    _createVNode(_component_LeaderboardTableCard, {
      title: "Rich List",
      records: _ctx.balanceLeaderboard
    }, null, 8, ["records"]),
    _createVNode(_component_LeaderboardTableCard, {
      title: "Top Score",
      records: _ctx.scoreLeaderboard
    }, null, 8, ["records"]),
    _createVNode(_component_LeaderboardTableCard, {
      title: "Weekly Top Active",
      records: _ctx.transactionsLeaderBoard
    }, null, 8, ["records"]),
    _createVNode(_component_LeaderboardTableCard, {
      title: "Weekly Top Dapps",
      records: _ctx.weeklyDappsLeaderboard
    }, null, 8, ["records"]),
    _createVNode(_component_LeaderboardTableCard, {
      title: "Weekly Top NFT Projects",
      records: _ctx.weeklyTopNFTLeaderboard
    }, null, 8, ["records"])
  ]))
}