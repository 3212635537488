import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col items-center p-3 space-y-4 justify-between flex-grow" }
const _hoisted_2 = { class: "h-20 flex justify-center items-center" }
const _hoisted_3 = { class: "w-full flex-grow flex flex-col items-center" }
const _hoisted_4 = { class: "w-full font-medium mb-2" }
const _hoisted_5 = {
  key: 0,
  class: "w-full grid grid-flow-row gap-1 mb-2",
  style: {"grid-template-columns":"auto min-content"}
}
const _hoisted_6 = {
  key: 1,
  class: "text-center text-gray-500 italic"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "header")
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("h4", _hoisted_4, _toDisplayString(_ctx.listTitle), 1),
      (!_ctx.empty)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _renderSlot(_ctx.$slots, "default")
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.emptyText), 1)),
      (!_ctx.empty)
        ? (_openBlock(), _createElementBlock("button", {
            key: 2,
            class: "mt-auto cursor-pointer bg-gray-200 dark:bg-gray-700 hover:bg-gray-300 dark:hover:bg-gray-900 px-2 rounded-sm truncate",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('expand')))
          }, _toDisplayString(_ctx.buttonText), 1))
        : _createCommentVNode("", true)
    ])
  ]))
}