
import { useNear } from '@/composables/useNear';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  props: {
    account: {
      type: String,
      required: true,
    },
    theme: {
      type: String as PropType<'dark' | 'light'>,
      required: false,
    },
  },
  setup() {
    const { network } = useNear();

    return {
      network,
    };
  },
});
