
import { CachedAccountRecord } from '@/services/near/indexer/types';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  props: {
    record: {
      type: Object as PropType<CachedAccountRecord>,
      required: true,
    },
    rank: {
      type: Number,
      required: true,
    },
  },
});
