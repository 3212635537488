
import ActionIcon from '@/components/ActionIcon.vue';
import Tooltip from '@/components/Tooltip.vue';
import { useNear } from '@/composables/useNear';
import { useTransactionActions } from '@/composables/useTransactionActions';
import { networks } from '@/services/near/indexer/networks';
import { UnifiedTransactionAction } from '@/services/near/indexer/types';
import { DateTime } from 'luxon';
import { defineComponent, ref, watch } from 'vue';
import DashboardCard from '../DashboardCard.vue';

export default defineComponent({
  components: {
    ActionIcon,
    DashboardCard,
    Tooltip,
  },
  setup() {
    const { account, network, timeframe } = useNear();
    const { actions } = useTransactionActions({ account, network, timeframe });
    const mostInteractedAccounts = ref<string[]>([]);
    const mostInteractedActions = ref<UnifiedTransactionAction[]>([]);

    watch(actions, actions => {
      const byQuantity = actions.reduce((map, item) => {
        map.set(
          item.signer_account_id,
          (map.get(item.signer_account_id) ?? 0) + 1,
        );
        map.set(
          item.receiver_account_id,
          (map.get(item.receiver_account_id) ?? 0) + 1,
        );
        return map;
      }, new Map<string, number>());

      // Remove current account
      byQuantity.delete(account.value);

      const sorted = Array.from(byQuantity.keys()).sort(
        (a, b) => byQuantity.get(b)! - byQuantity.get(a)!,
      );

      const top = sorted.slice(0, 3);

      const filteredActions = actions.filter(
        action =>
          (action.receiver_account_id === account.value &&
            action.signer_account_id === account.value) ||
          top.includes(action.receiver_account_id) ||
          top.includes(action.signer_account_id),
      );

      mostInteractedAccounts.value = top;
      mostInteractedActions.value = filteredActions;
    });

    return {
      currentAccount: account,
      actions,
      mostInteractedAccounts,
      mostInteractedActions,
      DateTime,
      network,
      networks,
    };
  },
});
