
import Modal from '@/components/Modal.vue';
import { useAccountView } from '@/composables/useAccountView';
import { useNear } from '@/composables/useNear';
import { useTransactionActions } from '@/composables/useTransactionActions';
import { networks } from '@/services/near/indexer/networks';
import {
  ActionKind,
  ITransferArgs,
  UnifiedTransactionAction,
} from '@/services/near/indexer/types';
import { DateTime } from 'luxon';
import { defineComponent, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import DashboardCard from '../DashboardCard.vue';
import HeaderListButtonTemplate from './HeaderListButtonTemplate.vue';

export default defineComponent({
  components: { DashboardCard, HeaderListButtonTemplate, Modal },
  setup() {
    const { account, network, timeframe } = useNear();
    const { actions } = useTransactionActions({ account, network, timeframe });
    const { view } = useAccountView({ account, network, finality: 'final' });
    const transfers = ref(
      [] as {
        account: string;
        amount: string;
        incoming: boolean;
        action: UnifiedTransactionAction;
      }[],
    );

    watch([actions, account], ([actions, account]) => {
      const transferActions = actions.filter(
        action => action.action_kind === ActionKind.TRANSFER,
      );
      transfers.value = transferActions.map(action => {
        const incoming = action.receiver_account_id === account;
        return {
          incoming,
          account: incoming
            ? action.signer_account_id
            : action.receiver_account_id,
          amount: (action.args as ITransferArgs).deposit,
          action,
        };
      });
    });

    const expandModalOpen = ref(false);

    const router = useRouter();

    const r = router.resolve({
      name: 'embed:balance',
      params: {
        network: network.value,
        account: account.value,
      },
      query: {
        t: timeframe.value,
      },
    });

    return {
      transfers,
      view,
      expandModalOpen,
      network,
      networks,
      DateTime,
    };
  },
});
