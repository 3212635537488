
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    thickness: {
      type: Number,
      default: 10,
    },
    trackThickness: {
      type: Number,
      default: 10,
    },
    color: {
      type: String,
      default: 'blue',
    },
    trackColor: {
      type: String,
      default: '#ddd',
    },
    lineCap: {
      type: String as () => 'round' | 'square',
      default: 'square',
    },
    fraction: {
      type: Number,
      required: true,
    },
  },
});
