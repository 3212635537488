
import { useAchievedBadges } from '@/composables/badges/useAchievedBadges';
import { useNear } from '@/composables/useNear';
import { defineComponent } from 'vue';
import BadgeWidget from './quests/BadgeWidget.vue';

export default defineComponent({
  components: {
    BadgeWidget,
  },
  data() {
    const { account, network } = useNear();
    const { achievedBadges, isLoading } = useAchievedBadges({
      account,
      network,
    });

    return {
      achievedBadges,
      isLoading,
    };
  },
});
