
import { defineComponent, Component, PropType } from 'vue';
import { RouteLocation } from 'vue-router';
import SectionLinkInternal from './SectionLinkInternal.vue';

export default defineComponent({
  components: {
    SectionLinkInternal,
  },
  props: {
    to: {
      type: [String, Object],
      required: true,
    },
    icon: {
      type: [Object, Function],
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    external: {
      type: Boolean,
      default: false,
    },
  },
});
