
import BadgeCard from '@/components/badges/BadgeCard.vue';
import { IBadgeDescriptor } from '@/composables/badges/badges';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  components: {
    BadgeCard,
  },
  props: {
    badge: {
      type: Object as PropType<IBadgeDescriptor>,
      required: true,
    },
  },
});
