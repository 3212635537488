
import { defineComponent, onMounted, ref } from 'vue';

export default defineComponent({
  name: 'client-only',
  setup() {
    const isClient = ref(false);

    onMounted(() => {
      isClient.value = true;
    });

    return {
      isClient,
    };
  },
});
