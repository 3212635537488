<template>
  <svg
    fill="none"
    version="1.1"
    viewBox="0 0 48 48"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m24 0c-13.255 0-24 10.745-24 24s10.745 24 24 24 24-10.745 24-24-10.745-24-24-24zm-10.359 12c0.66591-0.03052 2.3629 0.15333 5.291 2.127 1.6301-0.4509 3.3791-0.67599 5.1191-0.68359 1.7349 0.0102 3.4845 0.2341 5.1172 0.6875 3.9016-2.6314 5.6152-2.084 5.6152-2.084 1.114 2.8011 0.41458 4.8667 0.20508 5.3809 1.3108 1.4233 2.0996 3.2398 2.0996 5.4609 0 7.8132-4.777 9.5332-9.3301 10.037 0.7333 0.6281 1.3867 1.8686 1.3867 3.7656 0 1.8196-0.011931 3.4025-0.019531 4.4512-0.0038 0.5189-0.005859 0.90645-0.005859 1.127 0 0.108 0.015575 0.22032 0.046875 0.32812-1.6295 0.4485-3.347 0.6875-5.1191 0.6875-1.7698 0-3.4836-0.23805-5.1113-0.68555 0.0324-0.1083 0.048828-0.22158 0.048828-0.33008 0-0.2244-0.004566-0.61964-0.009766-1.1484-0.0059-0.6114-0.014031-1.4006-0.019531-2.3105-5.685 1.2283-6.8848-2.7266-6.8848-2.7266-0.9275-2.3502-2.2695-2.9766-2.2695-2.9766-1.8575-1.2613 0.13867-1.2363 0.13867-1.2363 2.0517 0.1443 3.1289 2.0977 3.1289 2.0977 1.8243 3.1075 4.7861 2.2112 5.9512 1.6895 0.184-1.3145 0.71168-2.2116 1.2969-2.7207-4.5378-0.5141-9.3105-2.2598-9.3105-10.053 0-2.2211 0.79727-4.0356 2.1055-5.459-0.2121-0.5141-0.91228-2.5842 0.19922-5.3828 0 0 0.10811-0.032796 0.33008-0.042969z"
      fill="currentColor"
    />
  </svg>
</template>
