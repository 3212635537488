import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewbox: "0 0 118 118"
}
const _hoisted_2 = {
  "transform-origin": "center center",
  transform: "rotate(-90) scale(.8)"
}
const _hoisted_3 = ["stroke", "stroke-width", "stroke-dasharray"]
const _hoisted_4 = ["stroke", "stroke-width", "stroke-dashoffset", "stroke-dasharray", "stroke-linecap"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("g", _hoisted_2, [
      _createElementVNode("circle", {
        cx: "50%",
        cy: "50%",
        r: "50%",
        fill: "transparent",
        stroke: _ctx.trackColor,
        "stroke-width": _ctx.trackThickness,
        "stroke-dasharray": Math.PI * 100 + '%',
        style: {"transition":"all 1.2s cubic-bezier(0.57, 0.13, 0.18, 0.98) 0s"}
      }, null, 8, _hoisted_3),
      _createElementVNode("circle", {
        fill: "transparent",
        cx: "50%",
        cy: "50%",
        r: "50%",
        stroke: _ctx.color,
        "stroke-width": _ctx.thickness,
        "stroke-dashoffset": (1 - _ctx.fraction) * Math.PI * 100 + '%',
        "stroke-dasharray": Math.PI * 100 + '%',
        "stroke-linecap": _ctx.lineCap,
        style: {"transition":"all 1.2s cubic-bezier(0.57, 0.13, 0.18, 0.98) 0s"}
      }, null, 8, _hoisted_4)
    ])
  ]))
}