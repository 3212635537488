
import { defineComponent } from 'vue';

export default defineComponent({
  emits: ['expand'],
  props: {
    listTitle: {
      type: String,
      required: true,
    },
    buttonText: {
      type: String,
      required: true,
    },
    empty: {
      type: Boolean,
      default: false,
    },
    emptyText: {
      type: String,
      default: 'Nothing to show',
    },
  },
});
