<template>
  <div
    class="
      px-12
      py-4
      animate-pulse
      flex flex-col
      items-center
      justify-center
      relative
    "
  >
    <div class="top-1/3 absolute mr-80 space-y-3">
      <div class="bg-gray-200 rounded-md w-24 h-4"></div>
      <div class="bg-gray-200 rounded-md w-12 h-4"></div>
    </div>
    <div class="top-1/4 absolute ml-72 space-y-3">
      <div class="bg-gray-200 rounded-md w-12 h-4"></div>
    </div>
    <div class="top-2/3 absolute ml-80 space-y-3 flex flex-col items-end">
      <div class="bg-gray-200 rounded-md w-24 h-4"></div>
      <div class="bg-gray-200 rounded-md w-36 h-4"></div>
    </div>
    <div class="rounded-full bg-gray-200 w-56 h-56"></div>
  </div>
</template>
