
import { useNear } from '@/composables/useNear';
import { isContract } from '@/utils/near';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import {
  ChevronDownIcon,
  ExclamationCircleIcon,
  UserCircleIcon,
} from 'heroicons-vue3/outline';
import { computed, defineComponent, ref } from 'vue';
import Modal from '../Modal.vue';

export default defineComponent({
  components: {
    Menu,
    MenuButton,
    MenuItems,
    MenuItem,
    UserCircleIcon,
    ChevronDownIcon,
    ExclamationCircleIcon,
    Modal,
  },
  setup() {
    const { walletAuth, account, timeframe, network, accountView } = useNear();
    const canSignIn = computed(() => isContract(accountView.value.code_hash));

    const isAccountErrorModalOpen = ref(false);

    const signIn = () => {
      walletAuth.signIn();
    };

    const signOut = () => {
      walletAuth.signOut();
    };

    const userMenuItemClass = (active: boolean) => [
      active ? 'text-black dark:text-white bg-gray-100 dark:bg-gray-800' : '',
      'block py-2 px-4 truncate',
    ];

    return {
      account,
      network,
      timeframe,
      walletAuth,
      canSignIn,
      signIn,
      signOut,
      isAccountErrorModalOpen,
      userMenuItemClass,
    };
  },
});
