
import Footer from '@/components/Footer.vue';
import ContactEmailIcon from '@/components/icons/ContactEmailIcon.vue';
import ContactYouTubeIcon from '@/components/icons/ContactYouTubeIcon.vue';
import ContactGithubIcon from '@/components/icons/ContactGithubIcon.vue';
import ContactTelegramIcon from '@/components/icons/ContactTelegramIcon.vue';
import ContactTwitterIcon from '@/components/icons/ContactTwitterIcon.vue';
import TopBar from '@/components/navigation/TopBar.vue';
import { DateTime } from 'luxon';
import { defineComponent } from 'vue';
import RoadmapCard from './story/RoadmapCard.vue';
import { RoadmapTask, tasks } from './story/tasks';
import TeamMemberCard from './story/TeamMemberCard.vue';

export default defineComponent({
  components: {
    TopBar,
    Footer,
    RoadmapCard,
    TeamMemberCard,
    ContactGithubIcon,
    ContactEmailIcon,
    ContactTelegramIcon,
    ContactTwitterIcon,
    ContactYouTubeIcon,
  },
  setup() {
    const futureTasks: RoadmapTask[] = [];
    const currentTasks: RoadmapTask[] = [];
    const completedTasks: RoadmapTask[] = [];

    const futureTaskColor = (task: RoadmapTask) => {
      const then = DateTime.fromJSDate(task.date);
      const diff = then.diffNow('months').months;
      if (diff > 3) {
        return 'bg-gray-700';
      } else if (diff > 2) {
        return 'bg-purple-500';
      } else if (diff > 1) {
        return 'bg-indigo-600';
      } else {
        return 'bg-blue-500';
      }
    };

    const currentMonth = DateTime.now().startOf('month').toMillis();

    tasks
      .sort((a, b) => a.date.getTime() - b.date.getTime())
      .forEach(task => {
        if (task.done) {
          completedTasks.unshift(task);
        } else if (
          DateTime.fromJSDate(task.date).startOf('month').toMillis() ===
          currentMonth
        ) {
          currentTasks.push(task);
        } else {
          futureTasks.push(task);
        }
      });

    return {
      futureTasks,
      currentTasks,
      completedTasks,
      futureTaskColor,
    };
  },
});
