import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BadgeCard = _resolveComponent("BadgeCard")!

  return (_openBlock(), _createBlock(_component_BadgeCard, {
    group: _ctx.badge.group,
    name: _ctx.badge.badge_name,
    description: _ctx.badge.badge_description,
    fraction: _ctx.badge.rarity_fraction,
    achieved: _ctx.badge.achieved
  }, null, 8, ["group", "name", "description", "fraction", "achieved"]))
}