import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SectionLinkInternal = _resolveComponent("SectionLinkInternal")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_ctx.external)
    ? (_openBlock(), _createBlock(_component_SectionLinkInternal, {
        key: 0,
        external: _ctx.external,
        icon: _ctx.icon,
        name: _ctx.name,
        href: _ctx.to,
        isActive: false
      }, null, 8, ["external", "icon", "name", "href"]))
    : (_openBlock(), _createBlock(_component_router_link, {
        key: 1,
        custom: "",
        to: _ctx.to
      }, {
        default: _withCtx(({ href, navigate, isActive }) => [
          _createVNode(_component_SectionLinkInternal, {
            external: _ctx.external,
            icon: _ctx.icon,
            name: _ctx.name,
            href: href,
            isActive: isActive,
            onClick: navigate
          }, null, 8, ["external", "icon", "name", "href", "isActive", "onClick"])
        ]),
        _: 1
      }, 8, ["to"]))
}