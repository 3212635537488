<template>
  <div class="inline-flex relative w-8 h-8" style="vertical-align: middle">
    <svg
      class="w-8 h-8"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.9524 1.67862C15.984 0.329164 18.016 0.329164 19.0476 1.67862L20.9281 4.13867C21.4893 4.87289 22.4007 5.2504 23.3168 5.12809L26.386 4.71829C28.0696 4.49349 29.5065 5.93038 29.2817 7.61401L28.8719 10.6832C28.7496 11.5993 29.1271 12.5107 29.8613 13.0719L32.3214 14.9524C33.6708 15.984 33.6708 18.016 32.3214 19.0476L29.8613 20.9281C29.1271 21.4893 28.7496 22.4007 28.8719 23.3168L29.2817 26.386C29.5065 28.0696 28.0696 29.5065 26.386 29.2817L23.3168 28.8719C22.4007 28.7496 21.4893 29.1271 20.9281 29.8613L19.0476 32.3214C18.016 33.6708 15.984 33.6708 14.9524 32.3214L13.0719 29.8613C12.5107 29.1271 11.5993 28.7496 10.6832 28.8719L7.61401 29.2817C5.93038 29.5065 4.49349 28.0696 4.71829 26.386L5.12809 23.3168C5.2504 22.4007 4.87289 21.4893 4.13867 20.9281L1.67862 19.0476C0.329164 18.016 0.329164 15.984 1.67862 14.9524L4.13867 13.0719C4.87289 12.5107 5.2504 11.5993 5.12809 10.6832L4.71829 7.61401C4.49349 5.93038 5.93038 4.49349 7.61401 4.71829L10.6832 5.12809C11.5993 5.2504 12.5107 4.87289 13.0719 4.13867L14.9524 1.67862Z"
        fill="currentColor"
      />
    </svg>
    <div
      class="
        absolute
        top-0
        left-0
        w-full
        h-full
        flex
        justify-center
        items-center
      "
    >
      <span class="text-white font-bold"><slot /></span>
    </div>
  </div>
</template>
