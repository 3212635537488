import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Transition as _Transition, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = { class: "relative" }
const _hoisted_2 = { class: "block truncate text-xl text-black text-center" }
const _hoisted_3 = { class: "absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SelectorIcon = _resolveComponent("SelectorIcon")!
  const _component_ListboxButton = _resolveComponent("ListboxButton")!
  const _component_CheckIcon = _resolveComponent("CheckIcon")!
  const _component_ListboxOption = _resolveComponent("ListboxOption")!
  const _component_ListboxOptions = _resolveComponent("ListboxOptions")!
  const _component_Listbox = _resolveComponent("Listbox")!
  const _component_client_only = _resolveComponent("client-only")!

  return (_openBlock(), _createBlock(_component_client_only, null, {
    default: _withCtx(() => [
      _createVNode(_component_Listbox, _mergeProps({ as: "div" }, _ctx.$attrs, {
        modelValue: _ctx.selectedItem,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedItem) = $event))
      }), {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_ListboxButton, { class: "w-full bg-white cursor-pointer relative rounded-sm p-3 pr-10 text-left sm:text-sm" }, {
              default: _withCtx(() => [
                _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.selectedItem.text), 1),
                _createElementVNode("span", _hoisted_3, [
                  _createVNode(_component_SelectorIcon, {
                    class: "h-6 w-6 text-gray-700",
                    "aria-hidden": "true"
                  })
                ])
              ]),
              _: 1
            }),
            _createVNode(_Transition, {
              "leave-active-class": "transition ease-in duration-100",
              "leave-from-class": "opacity-100",
              "leave-to-class": "opacity-0"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ListboxOptions, { class: "absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-sm py-1 ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none text-xl" }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.timeframes, (timeframe) => {
                      return (_openBlock(), _createBlock(_component_ListboxOption, {
                        as: "template",
                        key: timeframe,
                        value: timeframe
                      }, {
                        default: _withCtx(({ active, selected }) => [
                          _createElementVNode("li", {
                            class: _normalizeClass([
                  active ? 'text-white bg-green-600' : 'text-gray-900',
                  'cursor-pointer select-none relative py-2 pl-3 pr-9',
                ])
                          }, [
                            _createElementVNode("span", {
                              class: _normalizeClass([
                    selected ? 'font-semibold' : 'font-normal',
                    'block truncate',
                  ])
                            }, _toDisplayString(timeframe.text), 3),
                            selected
                              ? (_openBlock(), _createElementBlock("span", {
                                  key: 0,
                                  class: _normalizeClass([
                    active ? 'text-white' : 'text-green-600',
                    'absolute inset-y-0 right-0 flex items-center pr-4',
                  ])
                                }, [
                                  _createVNode(_component_CheckIcon, {
                                    class: "h-6 w-6",
                                    "aria-hidden": "true"
                                  })
                                ], 2))
                              : _createCommentVNode("", true)
                          ], 2)
                        ]),
                        _: 2
                      }, 1032, ["value"]))
                    }), 128))
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ])
        ]),
        _: 1
      }, 16, ["modelValue"])
    ]),
    _: 1
  }))
}