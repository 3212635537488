<template>
  <svg
    fill="none"
    version="1.1"
    viewBox="0 0 48 48"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m24 0c-13.255 0-24 10.745-24 24s10.745 24 24 24 24-10.745 24-24-10.745-24-24-24zm-1.4785 14c0.48997-0.001945 0.98065 2.84e-4 1.4707 0.005859-1e-6 0 9.4028 7.41e-4 11.75 0.61914 0.6271 0.1619 1.2024 0.47978 1.6719 0.92578 0.45625 0.43218 0.78708 0.98104 0.95703 1.5859v0.001953c0.44764 2.2686 0.65749 4.5785 0.625 6.8906 0.0149 2.3031-0.1937 4.6009-0.625 6.8633-0.165 0.5971-0.49453 1.147-0.95703 1.5879-0.4695 0.446-1.0448 0.76378-1.6719 0.92578-3.9013 0.4357-7.8247 0.63249-11.75 0.58789-1e-6 0-9.3578-8.8e-5 -11.736-0.61719-0.6349-0.1687-1.2121-0.48673-1.6758-0.92773s-0.79752-0.98754-0.96875-1.5859c-0.42119-2.2661-0.62633-4.5635-0.61133-6.8633-0.01463-2.3019 0.191-4.6001 0.61133-6.8633 0.17498-0.6009 0.51016-1.149 0.97266-1.5938 0.4706-0.4493 1.0448-0.77532 1.6719-0.94922 3.4093-0.38115 6.8358-0.58014 10.266-0.59375zm-1.5195 5.7246v8.5527l7.8242-4.2754-7.8242-4.2773z"
      fill="currentColor"
    />
  </svg>
</template>
