<template>
  <svg
    class="w-3"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.26585 4.31119e-07H13.7308C13.8022 4.31119e-07 13.8707 0.0283658 13.9211 0.0788564C13.9716 0.129347 14 0.197827 14 0.269231V4.73415C14.0001 4.78747 13.9844 4.83962 13.9548 4.88398C13.9252 4.92835 13.8831 4.96293 13.8339 4.98335C13.7846 5.00376 13.7304 5.0091 13.6781 4.99867C13.6258 4.98824 13.5778 4.96252 13.5402 4.92477L11.8785 3.26308L7.84 7.30154C7.68689 7.44421 7.48438 7.52188 7.27513 7.51819C7.06588 7.5145 6.86624 7.42973 6.71825 7.28175C6.57027 7.13376 6.4855 6.93412 6.48181 6.72487C6.47812 6.51562 6.55579 6.31311 6.69846 6.16L10.7369 2.12154L9.07523 0.459847C9.03748 0.422192 9.01176 0.374177 9.00133 0.321888C8.99091 0.269599 8.99624 0.215391 9.01665 0.166136C9.03707 0.116881 9.07165 0.0747973 9.11602 0.0452203C9.16038 0.0156434 9.21253 -9.49655e-05 9.26585 4.31119e-07ZM1.88462 1.07692C1.38478 1.07692 0.905425 1.27548 0.551991 1.62891C0.198557 1.98235 0 2.46171 0 2.96154V12.1154C0 13.1557 0.844308 14 1.88462 14H11.0385C11.5383 14 12.0177 13.8014 12.3711 13.448C12.7245 13.0946 12.9231 12.6152 12.9231 12.1154V8.34616C12.9231 8.13194 12.838 7.9265 12.6865 7.77503C12.535 7.62356 12.3296 7.53846 12.1154 7.53846C11.9012 7.53846 11.6957 7.62356 11.5443 7.77503C11.3928 7.9265 11.3077 8.13194 11.3077 8.34616V12.1154C11.3077 12.1868 11.2793 12.2553 11.2288 12.3058C11.1783 12.3563 11.1099 12.3846 11.0385 12.3846H1.88462C1.81321 12.3846 1.74473 12.3563 1.69424 12.3058C1.64375 12.2553 1.61538 12.1868 1.61538 12.1154V2.96154C1.61538 2.89013 1.64375 2.82165 1.69424 2.77116C1.74473 2.72067 1.81321 2.69231 1.88462 2.69231H5.65385C5.86806 2.69231 6.0735 2.60721 6.22497 2.45574C6.37644 2.30427 6.46154 2.09883 6.46154 1.88462C6.46154 1.6704 6.37644 1.46496 6.22497 1.31349C6.0735 1.16202 5.86806 1.07692 5.65385 1.07692H1.88462Z"
      fill="currentColor"
    />
  </svg>
</template>
