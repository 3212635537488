<template>
  <svg viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.20504 13.75H0.745932C0.33419 13.75 0 14.03 0 14.375V29.375C0 29.7199 0.33419 29.9999 0.745932 29.9999H8.20504C8.61678 29.9999 8.95097 29.7199 8.95097 29.3749V14.375C8.9509 14.03 8.61825 13.75 8.20504 13.75Z"
      fill="currentColor"
    />
    <path
      d="M19.2146 0H11.7555C11.3438 0 11.0096 0.280019 11.0096 0.625018V29.375C11.0096 29.72 11.3438 30 11.7555 30H19.2146C19.6264 30 19.9606 29.72 19.9606 29.375V0.625018C19.9606 0.280019 19.6264 0 19.2146 0Z"
      fill="currentColor"
    />
    <path
      d="M30.2541 6.25001H22.795C22.3832 6.25001 22.049 6.53003 22.049 6.87503V29.375C22.049 29.72 22.3832 30 22.795 30H30.2541C30.6658 30 31 29.72 31 29.375V6.87503C31 6.52997 30.6658 6.25001 30.2541 6.25001Z"
      fill="currentColor"
    />
  </svg>
</template>
