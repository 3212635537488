
import Alert from '@/components/Alert.vue';
import Modal from '@/components/Modal.vue';
import { useContract } from '@/composables/contract/useContract';
import { useNear } from '@/composables/useNear';
import { useTransactionResultFromUrl } from '@/composables/useTransactionResultFromUrl';
import { getDeterministicHueColor } from '@/utils/deterministicColor';
import { isContract } from '@/utils/near';
import { CheckCircleIcon, XCircleIcon } from 'heroicons-vue3/solid';
import { interfaces } from 'near-contract-parser';
import { computed, defineComponent, reactive, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import Method from './contract/Method.vue';
import TransactionResult from './contract/TransactionResult.vue';
import DashboardCard from './overview/DashboardCard.vue';

export default defineComponent({
  components: {
    Alert,
    DashboardCard,
    Method,
    Modal,
    TransactionResult,
    XCircleIcon,
    CheckCircleIcon,
  },
  setup() {
    const { account, network, accountView } = useNear();
    const accountIsContract = computed(() =>
      isContract(accountView.value.code_hash),
    );
    const { contract, isLoading } = useContract({ account, network });
    const args = reactive(new Map<string, { name: string; value: string }>());

    const { transactionHashes, errorMessage, errorCode } =
      useTransactionResultFromUrl();

    // Saved so that the values don't disappear immediately upon modal close
    const savedTransactionHashes = ref<string[]>([]);
    const savedErrorMessage = ref<string>();
    const savedErrorCode = ref<string>();
    watch(
      [transactionHashes, errorMessage, errorCode],
      ([hashes, errorMessage, errorCode]) => {
        if (hashes.length > 0) {
          savedTransactionHashes.value = hashes;
        }

        if (errorMessage) {
          savedErrorMessage.value = decodeURIComponent(errorMessage);
        }

        if (errorCode) {
          savedErrorCode.value = errorCode;
        }
      },
      { immediate: true },
    );

    const isTransactionResultsModalOpen = computed(
      () => transactionHashes.value.length > 0 || !!errorCode.value,
    );
    const router = useRouter();
    const closeTransactionResultsModal = async () => {
      await router.push({
        name: 'contract',
      });
    };

    return {
      account,
      isTransactionResultsModalOpen,
      accountIsContract,
      closeTransactionResultsModal,
      transactionHashes: savedTransactionHashes,
      errorMessage: savedErrorMessage,
      errorCode: savedErrorCode,
      contract,
      isLoading,
      interfaces,
      args,
      getDeterministicHueColor,
    };
  },
});
