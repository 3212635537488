
import { Timeframe } from '@/utils/timeframe';
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from '@headlessui/vue';
import { SelectorIcon } from 'heroicons-vue3/outline';
import { defineComponent, ref, toRefs, watch } from 'vue';

export default defineComponent({
  components: {
    Listbox,
    ListboxButton,
    ListboxOption,
    ListboxOptions,
    SelectorIcon,
  },
  props: {
    modelValue: {
      type: String as () => Timeframe,
      required: true,
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const timeframes = [
      {
        text: 'day',
        value: Timeframe.DAY,
      },
      {
        text: 'week',
        value: Timeframe.WEEK,
      },
      {
        text: 'month',
        value: Timeframe.MONTH,
      },
      {
        text: 'year',
        value: Timeframe.YEAR,
      },
      {
        text: 'all',
        value: Timeframe.ALL,
      },
    ];

    const fallbackTimeframe = timeframes[1];

    const selected = ref(
      timeframes.find(t => t.value === props.modelValue) ?? fallbackTimeframe,
    );

    watch(toRefs(props).modelValue, modelValue => {
      selected.value =
        timeframes.find(t => t.value === modelValue) ?? fallbackTimeframe;
    });

    watch(selected, () => {
      emit('update:modelValue', selected.value.value);
    });

    return {
      timeframes,
      selected,
    };
  },
});
