
import { useNear } from '@/composables/useNear';
import { useScore } from '@/composables/useScore';
import { defineComponent } from 'vue';
import DashboardCard from '../DashboardCard.vue';
import Star from '../Star.vue';

export default defineComponent({
  components: { DashboardCard, Star },
  setup() {
    const { account, network, timeframe } = useNear();
    const { accountLevel, isLoading: isScoreLoading } = useScore({
      account,
      network,
      timeframe,
    });

    return {
      accountLevel,
    };
  },
});
