
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from '@headlessui/vue';
import { defineComponent, PropType } from 'vue';
import { ChevronDownIcon } from 'heroicons-vue3/solid';

export default defineComponent({
  components: {
    Listbox,
    ListboxButton,
    ListboxOption,
    ListboxOptions,
    ChevronDownIcon,
  },
  props: {
    modelValue: {
      type: Object as PropType<any>,
    },
    label: {
      type: String as PropType<string>,
      default: '',
    },
    items: {
      type: Array as PropType<string[]>,
      required: true,
    },
    display: {
      type: Function as PropType<(e: any) => string>,
      default: (e: any) => e + '',
    },
    highlight: {
      type: Function as PropType<(e: any) => boolean>,
      default: (e: any) => false,
    },
  },
  emits: ['update:modelValue'],
});
