
import { defineComponent, provide } from 'vue';

let uniqueId = 0;

export default defineComponent({
  setup() {
    const id = 'teleport-' + uniqueId++;

    provide('autoTeleport', '#' + id);

    return {
      id,
    };
  },
});
