<template>
  <svg
    fill="none"
    version="1.1"
    viewBox="0 0 19 19"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m0.59749 9.9162 3.452 1.511 9.948-5.427-6 7 8.6 3.916c0.1471 0.0644 0.3075 0.0924 0.4677 0.0817 0.1602-0.0106 0.3155-0.0597 0.4527-0.1431s0.2524-0.1986 0.3357-0.3359c0.0833-0.1372 0.1323-0.2925 0.1429-0.4527l1-15c0.0111-0.17362-0.0231-0.34714-0.0994-0.5035s-0.192-0.29016-0.3357-0.38824c-0.1436-0.098081-0.3104-0.15706-0.4838-0.17113-0.1734-0.014073-0.3475 0.017245-0.5051 0.090873l-17 8c-0.17341 0.08227-0.31954 0.21262-0.42102 0.37554-0.10147 0.16292-0.15402 0.35156-0.15138 0.54348 0.0026334 0.19192 0.060334 0.37905 0.16624 0.53912 0.1059 0.16008 0.25556 0.28637 0.43116 0.36386zm5.4 9.084 4.776-2.316-4.776-2.228v4.544z"
      fill="currentColor"
    />
  </svg>
</template>
