<template>
  <svg viewBox="0 0 36 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20.5 1.44337C18.9529 0.550208 17.047 0.550208 15.5 1.44337L3.17944 8.55662C1.63244 9.44978 0.679443 11.1004 0.679443 12.8867V27.1132C0.679443 28.8996 1.63244 30.5502 3.17944 31.4434L15.5 38.5566C17.047 39.4498 18.9529 39.4498 20.5 38.5566L32.8205 31.4434C34.3675 30.5502 35.3205 28.8996 35.3205 27.1132V12.8867C35.3205 11.1004 34.3675 9.44978 32.8205 8.55662L20.5 1.44337ZM9.74995 12L6.99995 16.3846H12.5L9.74995 12ZM16.625 30.4154L12.5 17.8461H6.99995L16.625 30.4154ZM29 17.8461L19.375 30.4154L23.5 17.8461H29ZM18 31L13.875 17.8461H22.125L18 31ZM23.5 16.3846L26.25 12L29 16.3846H23.5ZM15.25 16.3846H20.75L18 12L15.25 16.3846ZM11.5924 12H16.6249L13.8749 16.3846L11.5924 12ZM24.875 12H19.375L22.125 16.3846L24.875 12Z"
      fill="currentColor"
    />
  </svg>
</template>
