import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createBlock as _createBlock, Transition as _Transition, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = { class: "block truncate text-base text-black dark:text-white" }
const _hoisted_2 = { class: "absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none" }
const _hoisted_3 = { class: "block truncate" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SelectorIcon = _resolveComponent("SelectorIcon")!
  const _component_ListboxButton = _resolveComponent("ListboxButton")!
  const _component_ListboxOption = _resolveComponent("ListboxOption")!
  const _component_ListboxOptions = _resolveComponent("ListboxOptions")!
  const _component_Float = _resolveComponent("Float")!
  const _component_AutoTeleport = _resolveComponent("AutoTeleport")!
  const _component_Listbox = _resolveComponent("Listbox")!
  const _component_client_only = _resolveComponent("client-only")!

  return (_openBlock(), _createBlock(_component_client_only, null, {
    default: _withCtx(() => [
      _createVNode(_component_Listbox, _mergeProps({ as: "div" }, _ctx.$attrs, {
        class: "relative",
        modelValue: _ctx.selectedOption,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = 
        $event => {
          const oldValue = _ctx.selectedOption.value;
          _ctx.$emit('update:modelValue', $event.value);
          _ctx.$emit('change', $event.value, oldValue);
        }
      )
      }), {
        default: _withCtx(() => [
          _createVNode(_component_ListboxButton, {
            ref: "anchorRef",
            class: "w-full h-full cursor-pointer relative rounded-sm p-2 pl-3 pr-8 border text-left bg-white hover:bg-gray-100 border-gray-300 dark:bg-gray-700 dark:hover:bg-gray-800 dark:border-gray-600 dark:text-white"
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.selectedOption.label), 1),
              _createElementVNode("span", _hoisted_2, [
                _createVNode(_component_SelectorIcon, {
                  class: "h-5 w-5 text-gray-400",
                  "aria-hidden": "true"
                })
              ])
            ]),
            _: 1
          }, 512),
          _createVNode(_component_AutoTeleport, null, {
            default: _withCtx(() => [
              (_ctx.anchorRef)
                ? (_openBlock(), _createBlock(_component_Float, {
                    key: 0,
                    snap: "left",
                    anchorRef: _ctx.anchorRef,
                    targetRef: _ctx.targetRef,
                    watchRef: _ctx.watchRef || {}
                  }, {
                    default: _withCtx(({ left, top, width }) => [
                      _createElementVNode("div", {
                        ref: "targetRef",
                        class: "fixed z-50",
                        style: _normalizeStyle({
              top: top + 'px',
              left: left + 'px',
              width: width + 'px',
            })
                      }, [
                        _createVNode(_Transition, {
                          "leave-active-class": "transition ease-in duration-100",
                          "leave-from-class": "opacity-100",
                          "leave-to-class": "opacity-0"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ListboxOptions, { class: "mt-1 w-full shadow-lg max-h-60 rounded-sm py-1 ring-1 ring-black ring-opacity-5 overflow-auto custom-scrollbar focus:outline-none text-base bg-white dark:bg-gray-700" }, {
                              default: _withCtx(() => [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
                                  return (_openBlock(), _createBlock(_component_ListboxOption, {
                                    ref_for: true,
                                    ref: "watchRef",
                                    as: "template",
                                    key: option.value,
                                    value: option
                                  }, {
                                    default: _withCtx(({ active }) => [
                                      _createElementVNode("li", {
                                        class: _normalizeClass(["text-black dark:text-white cursor-pointer select-none relative py-2 px-3", [active && 'bg-gray-100 dark:bg-gray-800']])
                                      }, [
                                        _createElementVNode("span", _hoisted_3, _toDisplayString(option.label), 1)
                                      ], 2)
                                    ]),
                                    _: 2
                                  }, 1032, ["value"]))
                                }), 128))
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ], 4)
                    ]),
                    _: 1
                  }, 8, ["anchorRef", "targetRef", "watchRef"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 16, ["modelValue"])
    ]),
    _: 1
  }))
}