
import BadgeTooltip from '@/components/badges/BadgeTooltip.vue';
import FunctionBadge from '@/components/badges/FunctionBadge.vue';
import NftBadge from '@/components/badges/NftBadge.vue';
import TransactionBadge from '@/components/badges/TransactionBadge.vue';
import Footer from '@/components/Footer.vue';
import ContactIcon from '@/components/icons/ContactIcon.vue';
import CombinedTopBar from '@/components/navigation/TopBar.vue';
import { BadgeGroup, IBadgeDescriptor } from '@/composables/badges/badges';
import { useAchievedBadges } from '@/composables/badges/useAchievedBadges';
import { useAccountView } from '@/composables/useAccountView';
import { useNear } from '@/composables/useNear';
import { useScore } from '@/composables/useScore';
import {
  AcademicCapIcon,
  AnnotationIcon,
  ClockIcon,
} from 'heroicons-vue3/solid';
import { defineComponent, onMounted, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import LeaderboardsIcon from './overview/icons/LeaderboardsIcon.vue';
import NftIcon from './overview/icons/NftIcon.vue';
import OverviewIcon from './overview/icons/OverviewIcon.vue';
import QuestsIcon from './overview/icons/QuestsIcon.vue';
import ContractIcon from './overview/icons/ContractIcon.vue';
import StatsIcon from './overview/icons/StatsIcon.vue';
import TransactionsIcon from './overview/icons/TransactionsIcon.vue';
import SectionLink from './overview/SectionLink.vue';
import Star from './overview/Star.vue';

export default defineComponent({
  components: {
    Footer,
    CombinedTopBar,
    SectionLink,
    Star,
    BadgeTooltip,
    ContactIcon,
    AnnotationIcon,
  },
  data() {
    const { account, network } = useNear();
    const { achievedBadges } = useAchievedBadges({ account, network });
    const badgeGroups = ref([] as IBadgeDescriptor[]);

    watch(achievedBadges, achievedBadges => {
      badgeGroups.value = achievedBadges;
    });

    return {
      badgeGroups,
    };
  },
  setup() {
    const { account, accountView, network, timeframe } = useNear();
    const {
      score,
      accountLevel,
      isLoading: isScoreLoading,
    } = useScore({
      account,
      network,
      timeframe,
    });

    const getTweetText = (routeName: string) => {
      // Switch based on route name
      switch (routeName) {
        case 'overview':
          return 'Check out my NEAR stats!';
        case 'transactions':
          return 'Check out my NEAR transactions!';
        case 'quests':
          return 'Check out my NEAR quests!';
        case 'leaderboards':
          return 'Check out my NEAR leaderboards!';
        default:
          return 'Check out NEAR statistics on stats.gallery!';
      }
    };

    const tweetButton = ref('');
    const route = useRoute();

    onMounted(() => {
      watch(
        route,
        () => {
          // Hardcoded HTML so that external Twitter script can read&replace
          tweetButton.value = `<a
              class="twitter-share-button"
              data-size="large"
              data-show-count="true"
              data-related="NEARProtocol,sudo_build"
              data-text="${getTweetText(route.name?.toString() ?? '')}"
              href="https://twitter.com/share?ref_src=${
                window?.location.href ?? ''
              }"
            >Tweet</a>`;

          // Reload Twitter button every page change
          const t = (window as any).twttr;

          if (t && t.widgets && t.widgets.load) {
            t.widgets.load();
          }
        },
        { immediate: true },
      );
    });

    return {
      account,
      accountView,
      network,
      timeframe,
      score,
      accountLevel,
      OverviewIcon,
      StatsIcon,
      TransactionsIcon,
      QuestsIcon,
      ContractIcon,
      LeaderboardsIcon,
      NftIcon,
      ClockIcon,
      AcademicCapIcon,
      TransactionBadge,
      FunctionBadge,
      NftBadge,
      tweetButton,
    };
  },
});
