
import {
  CachedAccountRecord,
  NearWeekCachedStats,
} from '@/services/near/indexer/types';
import { defineComponent, PropType } from 'vue';
import DashboardCard from '../overview/DashboardCard.vue';
import LeaderboardRow from './LeaderboardRow.vue';

export default defineComponent({
  components: { DashboardCard, LeaderboardRow },
  props: {
    title: {
      type: String,
      required: true,
    },
    records: {
      type: Object as PropType<CachedAccountRecord[] | NearWeekCachedStats[]>,
      required: true,
    },
    limit: {
      type: Number,
      default: 10,
    },
  },
});
