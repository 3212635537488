
import { Network } from '@/services/near/indexer/networks';
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from '@headlessui/vue';
import { SelectorIcon } from 'heroicons-vue3/outline';
import { defineComponent, ref, toRefs, watch } from 'vue';

export default defineComponent({
  components: {
    Listbox,
    ListboxButton,
    ListboxOption,
    ListboxOptions,
    SelectorIcon,
  },
  props: {
    modelValue: {
      type: String as () => Network,
      required: true,
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const networks = [
      {
        text: 'mainnet',
        value: Network.MAINNET,
        connected: true,
      },
      {
        text: 'testnet',
        value: Network.TESTNET,
        connected: false,
      },
    ];

    const selected = ref(
      networks.find(network => network.value === props.modelValue),
    );

    watch(toRefs(props).modelValue, modelValue => {
      selected.value = networks.find(network => network.value === modelValue);
    });

    watch(selected, selected => {
      emit('update:modelValue', selected?.value);
    });

    return {
      networks,
      selected,
    };
  },
});
