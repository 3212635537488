import { withKeys as _withKeys, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("input", {
    value: _ctx.modelValue,
    onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event.target.value))),
    onKeydown: _cache[1] || (_cache[1] = _withKeys(($event: any) => (_ctx.$emit('submit', $event.target.value)), ["enter"])),
    type: "text",
    class: "text-base font-normal block outline-none rounded-sm border p-2 z-10 text-black border-gray-300 disabled:bg-slate-200 disabled:text-gray-600 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
  }, null, 40, _hoisted_1))
}