import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createBlock as _createBlock, Transition as _Transition, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = { class: "relative" }
const _hoisted_2 = { class: "block truncate text-base text-black dark:text-white" }
const _hoisted_3 = { class: "absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none" }
const _hoisted_4 = { class: "block truncate" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SelectorIcon = _resolveComponent("SelectorIcon")!
  const _component_ListboxButton = _resolveComponent("ListboxButton")!
  const _component_ListboxOption = _resolveComponent("ListboxOption")!
  const _component_ListboxOptions = _resolveComponent("ListboxOptions")!
  const _component_Listbox = _resolveComponent("Listbox")!
  const _component_client_only = _resolveComponent("client-only")!

  return (_openBlock(), _createBlock(_component_client_only, null, {
    default: _withCtx(() => [
      _createVNode(_component_Listbox, _mergeProps({ as: "div" }, _ctx.$attrs, {
        modelValue: _ctx.selected,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selected) = $event))
      }), {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_ListboxButton, { class: "w-full bg-white hover:bg-gray-100 cursor-pointer relative rounded-sm p-2 pl-3 pr-8 border border-gray-300 text-left dark:bg-gray-700 dark:hover:bg-gray-800 dark:border-gray-600 dark:text-white" }, {
              default: _withCtx(() => [
                _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.selected.text), 1),
                _createElementVNode("span", _hoisted_3, [
                  _createVNode(_component_SelectorIcon, {
                    class: "h-5 w-5 text-gray-400",
                    "aria-hidden": "true"
                  })
                ])
              ]),
              _: 1
            }),
            _createVNode(_Transition, {
              "leave-active-class": "transition ease-in duration-100",
              "leave-from-class": "opacity-100",
              "leave-to-class": "opacity-0"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ListboxOptions, { class: "absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-sm py-1 ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none text-base dark:bg-gray-700" }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.timeframes, (timeframe) => {
                      return (_openBlock(), _createBlock(_component_ListboxOption, {
                        as: "template",
                        key: timeframe,
                        value: timeframe
                      }, {
                        default: _withCtx(({ active }) => [
                          _createElementVNode("li", {
                            class: _normalizeClass([
                  active ? 'bg-gray-100 dark:bg-gray-800' : '',
                  'cursor-pointer select-none relative py-2 px-3',
                ])
                          }, [
                            _createElementVNode("span", _hoisted_4, _toDisplayString(timeframe.text), 1)
                          ], 2)
                        ]),
                        _: 2
                      }, 1032, ["value"]))
                    }), 128))
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ])
        ]),
        _: 1
      }, 16, ["modelValue"])
    ]),
    _: 1
  }))
}