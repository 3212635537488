
import Alert from '@/components/Alert.vue';
import NormalButton from '@/components/form/NormalButton.vue';
import PendingButton from '@/components/form/PendingButton.vue';
import SmallPrimaryButton from '@/components/form/SmallPrimaryButton.vue';
import SmallSelectInput from '@/components/form/SmallSelectInput.vue';
import SmallTextInput from '@/components/form/SmallTextInput.vue';
import HelpTooltip from '@/components/HelpTooltip.vue';
import Modal from '@/components/Modal.vue';
import TransitionBob from '@/components/transitions/TransitionBob.vue';
import { useNear } from '@/composables/useNear';
import { getType, GuessableTypeString, guessType } from '@/utils/guessType';
import Big from 'big.js';
import { Buffer } from 'buffer';
import {
  CheckIcon,
  ChevronRightIcon,
  PlusIcon,
  SearchIcon,
  XCircleIcon,
  XIcon,
} from 'heroicons-vue3/solid';
import { CodeResult } from 'near-api-js/lib/providers/provider';
import { JsonType, StandardInterfaceArgument } from 'near-contract-parser';
import {
  defineComponent,
  PropType,
  reactive,
  Ref,
  ref,
  toRefs,
  watch,
} from 'vue';
import ArgumentRow from './ArgumentRow.vue';
import Labeled from './Labeled.vue';

interface IArgModel {
  type: GuessableTypeString | 'auto';
  value: string;
  active: boolean;
}

interface ICustomArgModel {
  name: string;
  uniqueId: number;
  model: IArgModel;
}

const strToType = (str: string, type: GuessableTypeString): unknown => {
  switch (type) {
    case 'json':
      return JSON.parse(str);
    case 'number':
      return Number(str);
    case 'boolean':
      return (
        str.trim().length > 0 && !['false', '0'].includes(str.toLowerCase())
      );
    case 'null':
      return null;
    default:
      return str + '';
  }
};

function jsonTypeToGuessableType(
  type: JsonType | JsonType[],
): GuessableTypeString {
  const best =
    type instanceof Array ? type.find(x => x !== 'null') ?? type[0] : type;

  switch (best) {
    case 'object':
    case 'array':
      return 'json';
    default:
      return best;
  }
}

export default defineComponent({
  components: {
    ChevronRightIcon,
    ArgumentRow,
    SmallPrimaryButton,
    Modal,
    PlusIcon,
    Labeled,
    SmallTextInput,
    SmallSelectInput,
    Alert,
    XCircleIcon,
    SearchIcon,
    PendingButton,
    HelpTooltip,
    TransitionBob,
    CheckIcon,
    XIcon,
    NormalButton,
  },
  props: {
    methodName: {
      type: String as PropType<string>,
      required: true,
    },
    label: {
      type: String as PropType<string>,
      default: () => '',
    },
    suggestedArguments: {
      type: Array as PropType<StandardInterfaceArgument[]>,
      default: () => [],
    },
  },
  setup(props) {
    const { walletAuth, account, connection, indexer } = useNear();
    let uniqueId = 0;
    const isModalOpen = ref(false);

    const customArguments = ref<ICustomArgModel[]>([]);

    const addCustomArgument = () => {
      customArguments.value = [
        ...customArguments.value,
        {
          name: '',
          uniqueId: uniqueId++,
          model: {
            type: 'auto',
            value: '',
            active: true,
          },
        },
      ];
    };

    const removeCustomArgument = (arg: ICustomArgModel) => {
      customArguments.value = customArguments.value.filter(x => x !== arg);
    };

    const argModels = reactive(new Map<string, IArgModel>());

    watch(
      toRefs(props).suggestedArguments,
      suggestedArguments => {
        suggestedArguments.forEach(arg => {
          if (!argModels.has(arg.name)) {
            argModels.set(arg.name, {
              type: jsonTypeToGuessableType(arg.type),
              value: '',
              active: true,
            });
          }
        });
      },
      { immediate: true },
    );

    const compileArguments = () => {
      // Construct args object
      const args: Record<string, any> = {};

      for (const [argName, model] of argModels.entries()) {
        if (model.active) {
          args[argName] =
            model.type === 'auto'
              ? guessType(model.value).value
              : strToType(model.value, model.type);
        }
      }

      // In the case of name conflict, custom args override suggested
      for (const arg of customArguments.value) {
        if (arg.model.active) {
          args[arg.name] =
            arg.model.type === 'auto'
              ? guessType(arg.model.value).value
              : strToType(arg.model.value, arg.model.type);
        }
      }

      return args;
    };

    const view = async () => {
      if (!connection.value) {
        alert('No connection to NEAR');
        return;
      }

      const args = compileArguments();

      try {
        const { result } = (await connection.value.connection.provider.query({
          request_type: 'call_function',
          account_id: account.value,
          method_name: props.methodName,
          args_base64: btoa(JSON.stringify(args)),
          finality: 'final',
        })) as CodeResult;

        const decoded = JSON.parse(Buffer.from(result).toString());

        viewError.value = '';
        viewResult.value = JSON.stringify(decoded, null, 2);
      } catch (e: any) {
        console.error('Error calling view method:', args, e);
        viewResult.value = '';
        viewError.value = e.message;
      }
    };

    const call = () => {
      if (
        !walletAuth.isSignedIn ||
        !walletAuth.isAccessible ||
        !walletAuth.account
      ) {
        // Should never happen; call button is disabled if not signed in
        alert('You must be signed in to call a function.');
        return;
      }

      const args = compileArguments();

      const tryParseDeposit = (() => {
        try {
          const b = Big(depositValue.value);
          if (b.s < 0) {
            return Big(0);
          } else {
            return b;
          }
        } catch (_) {
          return Big(0);
        }
      })();

      // Scale to units of yoctoNEAR
      const depositYocto = tryParseDeposit.times(
        depositUnits.value === 'near' ? Big('1e+24') : 1,
      );

      // Perform function call
      walletAuth.account.functionCall({
        contractId: account.value,
        methodName: props.methodName,
        args,
        attachedDeposit: depositYocto.toFixed(0),
      });
    };

    const guessMethodUsageState = ref<
      'normal' | 'pending' | 'success' | 'error'
    >('normal');
    const guessMethodUsage = async () => {
      guessMethodUsageState.value = 'pending';

      try {
        const [{ args: concreteArgs }] = await indexer.getMethodUsage({
          account: account.value,
          methodName: props.methodName,
        });

        // Sometimes args is an empty string (JSON.parse throws)
        const decoded = concreteArgs.args_base64
          ? JSON.parse(atob(concreteArgs.args_base64))
          : {};
        // Overwrite custom
        customArguments.value = Object.keys(decoded).map(
          key =>
            ({
              name: key,
              uniqueId: uniqueId++,
              model: {
                type: getType(decoded[key]),
                value: '',
                active: true,
              },
            } as ICustomArgModel),
        );

        depositUnits.value = 'yocto';
        depositValue.value = concreteArgs.deposit;

        // Disable suggested
        Array.from(argModels.keys()).forEach(p => {
          argModels.get(p)!.active = false;
        });

        guessMethodUsageState.value = 'success';
      } catch (_) {
        guessMethodUsageState.value = 'error';
        return;
      }
    };

    const viewResult = ref<string>('');
    const viewError = ref<string>('');
    const depositValue = ref<string>('');
    const depositUnits = ref<'near' | 'yocto'>('near');

    const updateValueWithUnits = (
      newValue: 'near' | 'yocto',
      oldValue: 'near' | 'yocto',
    ) => {
      if (newValue !== oldValue) {
        depositValue.value = (() => {
          try {
            const b = Big(depositValue.value);
            if (newValue === 'near') {
              return b.div('1e+24');
            } else {
              return b.mul('1e+24');
            }
          } catch (_) {
            return Big(0);
          }
        })().toString();
      }
    };

    return {
      call,
      view,
      walletAuth,
      isModalOpen,
      argModels,
      customArguments,
      addCustomArgument,
      removeCustomArgument,
      guessMethodUsage,
      guessMethodUsageState,
      viewResult,
      viewError,
      depositValue,
      depositUnits,
      updateValueWithUnits,
    };
  },
});
