import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, Transition as _Transition, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  ref: "elRef",
  class: "overflow-hidden rounded-sm shadow-lg bg-gray-900 bg-opacity-80 backdrop-filter backdrop-blur-sm text-white p-3"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PopoverButton = _resolveComponent("PopoverButton")!
  const _component_PopoverPanel = _resolveComponent("PopoverPanel")!
  const _component_Float = _resolveComponent("Float")!
  const _component_AutoTeleport = _resolveComponent("AutoTeleport")!
  const _component_Popover = _resolveComponent("Popover")!
  const _component_client_only = _resolveComponent("client-only")!

  return (_openBlock(), _createBlock(_component_client_only, null, {
    default: _withCtx(() => [
      _createVNode(_component_Popover, { as: "template" }, {
        default: _withCtx(({ open }) => [
          _createVNode(_component_PopoverButton, _mergeProps({ ref: "buttonRef" }, _ctx.$attrs), {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "trigger", { open: open })
            ]),
            _: 2
          }, 1040),
          _createVNode(_component_AutoTeleport, null, {
            default: _withCtx(() => [
              (_ctx.buttonRef)
                ? (_openBlock(), _createBlock(_component_Float, {
                    key: 0,
                    snap: "center",
                    anchorRef: _ctx.buttonRef,
                    targetRef: _ctx.panelRef,
                    watchRef: _ctx.elRef || {}
                  }, {
                    default: _withCtx(({ left, top }) => [
                      _createVNode(_Transition, {
                        "enter-active-class": "transition duration-200 ease-out",
                        "enter-from-class": "translate-y-1 opacity-0",
                        "enter-to-class": "translate-y-0 opacity-100",
                        "leave-active-class": "transition duration-150 ease-in",
                        "leave-from-class": "translate-y-0 opacity-100",
                        "leave-to-class": "translate-y-1 opacity-0"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_PopoverPanel, {
                            ref: "panelRef",
                            class: "fixed z-50 sm:w-full max-w-sm mt-2",
                            style: _normalizeStyle({
                left: left + 'px',
                top: top + 'px',
              })
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_1, [
                                _renderSlot(_ctx.$slots, "content", { open: open })
                              ], 512)
                            ]),
                            _: 2
                          }, 1032, ["style"])
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1032, ["anchorRef", "targetRef", "watchRef"]))
                : _createCommentVNode("", true)
            ]),
            _: 2
          }, 1024)
        ]),
        _: 3
      })
    ]),
    _: 3
  }))
}