<template>
  <div class="rounded-sm p-4">
    <div class="flex">
      <div class="flex-shrink-0">
        <slot name="icon" />
      </div>
      <div class="ml-3">
        <slot />
      </div>
    </div>
  </div>
</template>
