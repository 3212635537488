import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, Transition as _Transition } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "text-sm text-left text-gray-500 dark:text-gray-200 pb-1 px-1"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ChevronDownIcon = _resolveComponent("ChevronDownIcon")!
  const _component_ListboxButton = _resolveComponent("ListboxButton")!
  const _component_ListboxOption = _resolveComponent("ListboxOption")!
  const _component_ListboxOptions = _resolveComponent("ListboxOptions")!
  const _component_Listbox = _resolveComponent("Listbox")!
  const _component_client_only = _resolveComponent("client-only")!

  return (_openBlock(), _createBlock(_component_client_only, null, {
    default: _withCtx(() => [
      _createVNode(_component_Listbox, {
        as: "div",
        class: _normalizeClass([_ctx.$attrs.class, "relative"]),
        modelValue: _ctx.modelValue,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event)))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ListboxButton, { class: "w-full rounded-sm" }, {
            default: _withCtx(({ open }) => [
              (_ctx.label.length > 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.label), 1))
                : _createCommentVNode("", true),
              _createElementVNode("div", {
                class: _normalizeClass([[
            open || _ctx.highlight(_ctx.modelValue)
              ? 'bg-gray-500 text-gray-100 dark:bg-gray-900'
              : 'bg-gray-200 hover:bg-gray-300 dark:text-gray-200 dark:bg-gray-600 dark:hover:bg-gray-500',
          ], "w-full flex items-center justify-between text-sm font-medium py-1 px-3 rounded-sm cursor-pointer"])
              }, [
                _createTextVNode(_toDisplayString(_ctx.display(_ctx.modelValue)) + " ", 1),
                _createVNode(_component_ChevronDownIcon, {
                  class: "-mr-1 ml-2 h-5 w-5",
                  "aria-hidden": "true"
                })
              ], 2)
            ]),
            _: 1
          }),
          _createVNode(_Transition, {
            "enter-active-class": "transition ease-out duration-100",
            "enter-from-class": "transform opacity-0 scale-95",
            "enter-to-class": "transform opacity-100 scale-100",
            "leave-active-class": "transition ease-in duration-75",
            "leave-from-class": "transform opacity-100 scale-100",
            "leave-to-class": "transform opacity-0 scale-95"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ListboxOptions, { class: "absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-sm py-1 ring-1 ring-black ring-opacity-5 overflow-auto text-sm dark:bg-gray-700 custom-scrollbar" }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, i) => {
                    return (_openBlock(), _createBlock(_component_ListboxOption, {
                      key: i,
                      value: item
                    }, {
                      default: _withCtx(({ active }) => [
                        _createElementVNode("div", {
                          class: _normalizeClass([
                active ? 'bg-gray-100 dark:bg-gray-800' : '',
                'cursor-pointer select-none relative py-2 px-3',
              ])
                        }, _toDisplayString(_ctx.display(item)), 3)
                      ]),
                      _: 2
                    }, 1032, ["value"]))
                  }), 128))
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["class", "modelValue"])
    ]),
    _: 1
  }))
}