<template>
  <div class="px-12 py-4 animate-pulse flex flex-col">
    <div class="flex">
      <div class="flex-shrink-0 mr-4 flex flex-col space-y-12">
        <div class="bg-gray-200 rounded-md w-8 h-4"></div>
        <div class="bg-gray-200 rounded-md w-8 h-4"></div>
        <div class="bg-gray-200 rounded-md w-8 h-4"></div>
        <div class="bg-gray-200 rounded-md w-8 h-4"></div>
        <div class="bg-gray-200 rounded-md w-8 h-4"></div>
        <div class="bg-gray-200 rounded-md w-8 h-4"></div>
      </div>
      <div class="flex-1 flex items-end">
        <div class="flex-1 bg-gray-200 rounded-l-md h-1/3"></div>
        <div class="flex-1 bg-gray-200 rounded-t-md h-2/3"></div>
        <div class="flex-1 bg-gray-200 rounded-r-md h-1/2"></div>
      </div>
    </div>
    <div class="flex justify-evenly pt-3">
      <div class="w-8 h-4"></div>
      <div class="bg-gray-200 rounded-md w-1/6 h-4"></div>
      <div class="bg-gray-200 rounded-md w-1/6 h-4"></div>
      <div class="bg-gray-200 rounded-md w-1/6 h-4"></div>
      <div class="bg-gray-200 rounded-md w-1/6 h-4"></div>
    </div>
  </div>
</template>
