
import Modal from '@/components/Modal.vue';
import HelpTooltip from '@/components/HelpTooltip.vue';
import { useNear } from '@/composables/useNear';
import { CodeIcon, ArrowSmUpIcon } from 'heroicons-vue3/solid';
import { computed, defineComponent, ref } from 'vue';
import { useRouter } from 'vue-router';
import copy from 'clipboard-copy';

export default defineComponent({
  components: {
    HelpTooltip,
    Modal,
    CodeIcon,
    ArrowSmUpIcon,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    embed: {
      type: String,
      required: false,
    },
  },
  setup(props) {
    const isEmbedModalOpen = ref(false);

    const { account, network, timeframe } = useNear();

    const router = useRouter();

    const isEmbedded =
      router.currentRoute.value.name?.toString().startsWith('embed:') ?? false;

    const showEmbedButton = computed(() => !!props.embed && !isEmbedded);

    const getEmbedUrl = () => {
      if (props.embed) {
        const { href } = router.resolve({
          name: props.embed,
          params: {
            account: account.value,
            network: network.value,
          },
          query: {
            t: timeframe.value,
          },
        });

        return location.origin + href;
      } else {
        return '';
      }
    };

    const embedCode = computed(() => {
      const url = getEmbedUrl();
      return `<iframe src="${url}" width="320" height="380" frameborder="0" allowtransparency="true"></iframe>`;
    });

    const copyCode = () => {
      copy(embedCode.value)
        .then(() => {
          copyState.value = 'success';
        })
        .catch(() => {
          copyState.value = 'failure';
        })
        .then(() => {
          setTimeout(() => {
            copyState.value = 'pending';
          }, 3000);
        });
    };

    const copyState = ref('pending' as 'pending' | 'success' | 'failure');

    return {
      isEmbedModalOpen,
      showEmbedButton,
      embedCode,
      isEmbedded,
      copyCode,
      copyState,
    };
  },
});
