import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col items-center" }
const _hoisted_2 = { class: "text-gray-500 mt-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("label", _hoisted_1, [
    _renderSlot(_ctx.$slots, "default"),
    _createElementVNode("small", _hoisted_2, _toDisplayString(_ctx.label), 1)
  ]))
}