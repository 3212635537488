
import { useNear } from '@/composables/useNear';
import { useSingle } from '@/composables/useSingle';
import { defineComponent, ref, watch } from 'vue';
import { ArrowSmDownIcon, ArrowSmUpIcon } from 'heroicons-vue3/outline';
import BriefStat from '../BriefStat.vue';
import DashboardCard from '../DashboardCard.vue';
import { DateTime } from 'luxon';

export default defineComponent({
  components: { DashboardCard, BriefStat, ArrowSmDownIcon, ArrowSmUpIcon },
  setup() {
    const { account, network, timeframe } = useNear();
    const { value: sentCount, isLoading: isSentLoading } = useSingle(
      'sent-transaction-count',
      { account, network, timeframe },
      0,
    );
    const { value: receivedCount, isLoading: isReceivedLoading } = useSingle(
      'received-transaction-count',
      { account, network, timeframe },
      0,
    );
    const { value: creationDate, isLoading: isCreationLoading } = useSingle(
      'account-creation',
      { account, network, timeframe },
      0,
    );

    const { value: gasSpent, isLoading: isGasSpentLoading } = useSingle(
      'gas-spent',
      { account, network, timeframe },
      0,
    );
    const { value: gasTokensSpent, isLoading: isGasTokensSpentLoading } =
      useSingle('gas-tokens-spent', { account, network, timeframe }, 0);

    const durationDays = ref(0);

    watch(creationDate, creationDate => {
      durationDays.value = DateTime.fromMillis(creationDate / 1_000_000)
        .diffNow('days')
        .negate().days;
    });

    return {
      sentCount,
      receivedCount,
      creationDate,
      DateTime,
      durationDays,
      gasSpent,
      gasTokensSpent,
    };
  },
});
