
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  props: {
    mode: {
      type: String as PropType<'normal' | 'pending'>,
      default: 'normal',
    },
  },
});
