import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-502958dd"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "zw-container"
}
const _hoisted_2 = {
  key: 1,
  ref: "container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.autoresize)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", {
          ref: "container",
          style: _normalizeStyle(`width: ${_ctx.width}px`),
          class: "dyn-container"
        }, null, 4)
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, null, 512))
}