import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "py-2 relative flex" }
const _hoisted_2 = { class: "w-1/12 hidden sm:block text-center px-2 font-bold text-gray-300" }
const _hoisted_3 = { class: "flex-1 truncate px-2" }
const _hoisted_4 = {
  key: 0,
  class: "w-3/12 flex items-center justify-start px-2 font-medium",
  style: {"min-width":"4em"}
}
const _hoisted_5 = {
  key: 1,
  class: "w-2/12 px-2 font-medium",
  style: {"min-width":"4em"}
}
const _hoisted_6 = {
  key: 2,
  class: "w-2/12 px-2 font-medium",
  style: {"min-width":"4em"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_account_link = _resolveComponent("account-link")!
  const _component_near_symbol = _resolveComponent("near-symbol")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.rank), 1),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_account_link, {
        account: _ctx.record.account_id
      }, null, 8, ["account"])
    ]),
    (_ctx.record.balance !== undefined)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_near_symbol, { class: "w-5 hidden sm:block" }),
          _createTextVNode(" " + _toDisplayString(_ctx.$filters.number.compact(+_ctx.$filters.toNear(_ctx.record.balance))), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.record.balance !== undefined)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.$filters.number.compact(_ctx.record.score)), 1))
      : _createCommentVNode("", true),
    (_ctx.record.number_of_transactions !== undefined)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.$filters.number.compact(_ctx.record.number_of_transactions)), 1))
      : _createCommentVNode("", true)
  ]))
}