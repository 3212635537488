import { renderSlot as _renderSlot, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", _mergeProps(_ctx.$attrs, {
    class: ["py-2 px-4 border rounded-sm", {
      'text-gray-800 border-gray-300 bg-gray-100 hover:bg-gray-200':
        _ctx.mode === 'normal',
      'text-gray-800 border-gray-300 bg-gray-300 animate-pulse cursor-wait':
        _ctx.mode === 'pending',
    }],
    disabled: _ctx.mode === 'pending'
  }), [
    _renderSlot(_ctx.$slots, "default")
  ], 16, _hoisted_1))
}