import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col items-center space-y-3 p-4" }
const _hoisted_2 = { class: "font-bold" }
const _hoisted_3 = { class: "font-bold text-2xl" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h5", _hoisted_2, _toDisplayString(_ctx.title), 1),
    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.value), 1),
    _renderSlot(_ctx.$slots, "default")
  ]))
}