import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["target", "rel", "href"]
const _hoisted_2 = { class: "rounded-l-full py-3 pl-4 pr-3 flex" }
const _hoisted_3 = { class: "hidden md:flex flex-grow rounded-r-full py-3 items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_external_link_icon = _resolveComponent("external-link-icon")!

  return (_openBlock(), _createElementBlock("a", {
    class: _normalizeClass([
      _ctx.isActive
        ? 'text-gray-700 bg-white dark:text-white dark:bg-gray-600'
        : 'text-gray-500 dark:text-gray-300 hover:bg-gray-400 hover:bg-opacity-10',
      'font-medium w-16 md:w-60 rounded-l-full lg:rounded-full flex',
    ]),
    target: _ctx.external ? '_blank' : undefined,
    rel: _ctx.external ? 'noreferrer' : undefined,
    href: _ctx.href
  }, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.icon), { class: "w-6 h-6" }))
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createTextVNode(_toDisplayString(_ctx.name) + " ", 1),
      (_ctx.external)
        ? (_openBlock(), _createBlock(_component_external_link_icon, {
            key: 0,
            class: "text-gray-400 ml-2"
          }))
        : _createCommentVNode("", true)
    ])
  ], 10, _hoisted_1))
}