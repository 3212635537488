
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from '@headlessui/vue';
import { SelectorIcon } from 'heroicons-vue3/outline';
import { computed, defineComponent, PropType, ref } from 'vue';
import AutoTeleport from '../AutoTeleport.vue';
import Float from '../Float.vue';

export default defineComponent({
  components: {
    Listbox,
    ListboxButton,
    ListboxOption,
    ListboxOptions,
    SelectorIcon,
    Float,
    AutoTeleport,
  },
  props: {
    modelValue: {
      type: String as PropType<string>,
      required: true,
    },
    options: {
      type: Array as PropType<{ label: string; value: string }[]>,
      required: true,
    },
  },
  emits: ['update:modelValue', 'change'],
  setup(props) {
    const anchorRef = ref(null);
    const targetRef = ref(null);
    const watchRef = ref(null);

    const selectedOption = computed(() =>
      props.options.find(o => o.value === props.modelValue),
    );

    return {
      anchorRef,
      targetRef,
      watchRef,
      selectedOption,
    };
  },
});
