
import Chart from '@/components/Chart.vue';
import { useGaugeChart } from '@/composables/charts/useGaugeChart';
import { useNear } from '@/composables/useNear';
import { useTransactionActions } from '@/composables/useTransactionActions';
import { Timeframe } from '@/utils/timeframe';
import { defineComponent, ref, watch } from 'vue';
import DashboardCard from '../DashboardCard.vue';

export default defineComponent({
  components: {
    DashboardCard,
    Chart,
  },
  setup() {
    const { account, network } = useNear();
    const { actions, isLoading } = useTransactionActions({
      account,
      network,
      timeframe: Timeframe.WEEK,
    });
    const actionsLength = ref(0);
    watch(actions, actions => {
      actionsLength.value = actions.length;
    });

    const weeklyTransactionRateOption = useGaugeChart(actionsLength);

    return {
      weeklyTransactionRateOption,
      isLoading,
    };
  },
});
