
import { computed, defineComponent, PropType } from 'vue';
import SmallTextInput from '@/components/form/SmallTextInput.vue';
import SmallSelectInput from '@/components/form/SmallSelectInput.vue';
import { GuessableTypeString, guessType } from '@/utils/guessType';
import Labeled from './Labeled.vue';
import { XCircleIcon } from 'heroicons-vue3/solid';

export default defineComponent({
  components: {
    SmallTextInput,
    SmallSelectInput,
    Labeled,
    XCircleIcon,
  },
  props: {
    locked: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    active: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
    field: {
      type: String as PropType<string>,
      required: true,
    },
    type: {
      type: String as PropType<GuessableTypeString | 'auto'>,
      required: true,
    },
    value: {
      type: String as PropType<string>,
      required: true,
    },
  },
  emits: [
    'update:active',
    'update:field',
    'update:type',
    'update:value',
    'remove',
  ],
  setup(props) {
    const typeStrings = [
      {
        label: 'Auto',
        value: 'auto',
      },
      {
        label: 'String',
        value: 'string',
      },
      {
        label: 'Number',
        value: 'number',
      },
      {
        label: 'Boolean',
        value: 'boolean',
      },
      {
        label: 'Null',
        value: 'null',
      },
      {
        label: 'Raw JSON',
        value: 'json',
      },
    ];

    const guess = computed(() =>
      props.type === 'auto' ? guessType(props.value) : 'unknown',
    );

    return {
      typeStrings,
      guess,
    };
  },
});
