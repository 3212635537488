<template>
  <svg viewBox="0 0 36 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20.5 1.44337C18.9529 0.550208 17.047 0.550208 15.5 1.44337L3.17944 8.55662C1.63244 9.44978 0.679443 11.1004 0.679443 12.8867V27.1132C0.679443 28.8996 1.63244 30.5502 3.17944 31.4434L15.5 38.5566C17.047 39.4498 18.9529 39.4498 20.5 38.5566L32.8205 31.4434C34.3675 30.5502 35.3205 28.8996 35.3205 27.1132V12.8867C35.3205 11.1004 34.3675 9.44979 32.8205 8.55662L20.5 1.44337ZM19.6307 9.5478C19.451 9.78625 19.3539 10.0766 19.3542 10.375L19.3556 24.1113C19.3556 24.4756 19.5006 24.825 19.7587 25.0826C20.0168 25.3402 20.3669 25.4849 20.7319 25.4849C21.0969 25.4849 21.447 25.3402 21.7051 25.0826C21.9632 24.825 22.1082 24.4756 22.1082 24.1113V13.7404L23.6496 15.2802C23.7774 15.4078 23.9291 15.5091 24.0961 15.5782C24.2631 15.6473 24.4421 15.6829 24.6229 15.683C24.8037 15.683 24.9827 15.6476 25.1497 15.5786C25.3168 15.5096 25.4685 15.4084 25.5964 15.2809C25.7243 15.1534 25.8257 15.002 25.895 14.8353C25.9642 14.6686 25.9999 14.49 26 14.3096C26 14.1291 25.9645 13.9505 25.8954 13.7837C25.8262 13.617 25.7249 13.4655 25.5971 13.3379L21.8976 9.64561C21.7392 9.39258 21.5025 9.19792 21.2233 9.09107C20.9442 8.98423 20.6378 8.97101 20.3504 9.05342C20.0631 9.13582 19.8105 9.30936 19.6307 9.5478ZM16.3692 30.4522C16.5489 30.2137 16.646 29.9234 16.6457 29.625L16.6443 15.8887C16.6443 15.5244 16.4993 15.175 16.2412 14.9174C15.9831 14.6598 15.633 14.5151 15.268 14.5151C14.903 14.5151 14.5529 14.6598 14.2948 14.9174C14.0367 15.175 13.8917 15.5244 13.8917 15.8887V26.2596L12.3503 24.7198C12.0922 24.462 11.7421 24.3172 11.377 24.317C11.0119 24.3169 10.6617 24.4615 10.4035 24.7191C10.1452 24.9767 10.0001 25.3261 9.99995 25.6904C9.99982 26.0548 10.1447 26.4043 10.4028 26.6621L14.1023 30.3544C14.2607 30.6074 14.4974 30.8021 14.7766 30.9089C15.0557 31.0158 15.3621 31.029 15.6495 30.9466C15.9368 30.8642 16.1895 30.6906 16.3692 30.4522Z"
      fill="currentColor"
    />
  </svg>
</template>
