import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex-1 bg-white dark:bg-gray-800 rounded-md shadow-md p-5 flex flex-col space-y-3 items-center relative pt-32 mt-24 mx-3 mb-8" }
const _hoisted_2 = { class: "absolute w-full h-0 top-0" }
const _hoisted_3 = { class: "absolute w-full transform -translate-y-1/2 top-0 flex justify-center" }
const _hoisted_4 = { class: "text-xl font-medium" }
const _hoisted_5 = { class: "font-medium" }
const _hoisted_6 = { class: "italic px-9 pt-1 relative" }
const _hoisted_7 = { class: "flex space-x-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_QuoteIcon = _resolveComponent("QuoteIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _renderSlot(_ctx.$slots, "image")
      ])
    ]),
    _createElementVNode("header", _hoisted_4, _toDisplayString(_ctx.name), 1),
    _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.title), 1),
    _createElementVNode("p", null, [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _createElementVNode("blockquote", _hoisted_6, [
      _createVNode(_component_QuoteIcon, { class: "w-6 text-gray-400 absolute top-0 left-0" }),
      _renderSlot(_ctx.$slots, "quote")
    ]),
    _createElementVNode("div", _hoisted_7, [
      _renderSlot(_ctx.$slots, "contact")
    ])
  ]))
}