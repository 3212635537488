<template>
  <svg viewBox="0 0 25 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.336 10.6625C13.061 10.6375 12.7735 10.625 12.4985 10.625C12.3235 10.625 12.161 10.625 11.986 10.6375C9.87347 10.7375 7.91098 11.4625 6.29848 12.625L0.110984 1.3875C-0.0390156 1.1 -0.0390156 0.75 0.123484 0.4625C0.298484 0.175 0.598484 0 0.935983 0H6.56098C6.89848 0 7.19848 0.175 7.37348 0.4625L12.636 9.4625L13.336 10.6625Z"
      fill="currentColor"
    />
    <path
      d="M24.886 1.3875L18.6985 12.625C17.8235 11.9875 16.8485 11.4875 15.7985 11.15L13.6985 7.55L17.611 0.4875C17.786 0.1875 18.0985 0 18.436 0H24.061C24.3985 0 24.6985 0.175 24.8735 0.4625C25.036 0.75 25.036 1.1 24.886 1.3875Z"
      fill="currentColor"
    />
    <path
      d="M12.4985 12.5C7.6735 12.5 3.7485 16.425 3.7485 21.25C3.7485 26.075 7.6735 30 12.4985 30C17.3235 30 21.2485 26.075 21.2485 21.25C21.2485 16.425 17.3235 12.5 12.4985 12.5ZM17.2323 20.9463L15.531 22.69L15.9348 25.1625C15.9923 25.5163 15.8435 25.8725 15.5485 26.08C15.3885 26.1925 15.1985 26.25 15.0098 26.25C14.8535 26.25 14.6973 26.2113 14.556 26.1325L12.4985 24.9963L10.441 26.1338C10.1273 26.3075 9.73975 26.2875 9.44726 26.08C9.15351 25.8725 9.00476 25.5163 9.061 25.1625L9.46476 22.69L7.7635 20.9463C7.5185 20.695 7.4335 20.3263 7.546 19.9938C7.65725 19.66 7.94475 19.4188 8.2935 19.365L10.6073 19.0113L11.6498 16.79C11.9573 16.1313 13.0385 16.1313 13.346 16.79L14.3885 19.0113L16.7023 19.365C17.0498 19.4175 17.3385 19.66 17.4498 19.9938C17.5623 20.3263 17.4773 20.695 17.2323 20.9463Z"
      fill="currentColor"
    />
  </svg>
</template>
