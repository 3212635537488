
import { BadgeGroup } from '@/composables/badges/badges';
import { CheckIcon } from 'heroicons-vue3/solid';
import { defineComponent, ref, watch } from 'vue';
import BadgeIcon from './BadgeIcon.vue';

export default defineComponent({
  components: {
    CheckIcon,
    BadgeIcon,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    fraction: {
      type: Number,
      required: true,
    },
    group: {
      type: String as () => BadgeGroup,
      required: true,
    },
    achieved: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const rarityClass = ref('');
    const rarityName = ref('');
    const gildClass = ref('');

    watch(
      props,
      () => {
        if (props.fraction <= 0.0001) {
          rarityName.value = 'Legendary';
          rarityClass.value = 'rainbow';
          gildClass.value = 'to-gray-900';
        } else if (props.fraction <= 0.001) {
          rarityName.value = 'Epic';
          rarityClass.value = 'bg-purple-500';
          gildClass.value = 'to-purple-500';
        } else if (props.fraction <= 0.01) {
          rarityName.value = 'Super Rare';
          rarityClass.value = 'bg-blue-500';
          gildClass.value = 'to-blue-500';
        } else if (props.fraction <= 0.1) {
          rarityName.value = 'Rare';
          rarityClass.value = 'bg-yellow-500';
          gildClass.value = 'to-yellow-500';
        } else if (props.fraction <= 0.25) {
          rarityName.value = 'Uncommon';
          rarityClass.value = 'bg-green-600';
          gildClass.value = 'to-green-500';
        } else {
          rarityName.value = 'Common';
          rarityClass.value =
            'text-gray-500 ring-1 ring-gray-300 dark:text-gray-200 dark:bg-gray-700';
          gildClass.value = '';
        }
      },
      { immediate: true },
    );

    return {
      rarityName,
      rarityClass,
      gildClass,
    };
  },
});
