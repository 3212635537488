<template>
  <svg viewBox="0 0 30 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.74569 11.6747C8.30306 14.7862 11.8913 17.346 13.5 18.3813V21.1544L7.10888 24.899H22.9399L16.5 21.1261V18.3813C18.1088 17.3456 21.6969 14.7858 23.2543 11.6743C28.5643 11.1291 30 4.85494 30 1.46465C30 1.0762 29.842 0.703659 29.5607 0.428985C29.2794 0.154311 28.8978 0 28.5 0H1.5C1.10218 0 0.720644 0.154311 0.43934 0.428985C0.158035 0.703659 0 1.0762 0 1.46465C0 4.85494 1.43569 11.1291 6.74569 11.6747ZM24 8.45687V2.92929H26.8841C26.6567 4.68339 25.9838 7.50064 24 8.45687ZM6 2.92929V8.45687C4.01606 7.50064 3.34313 4.68266 3.11588 2.92929H6Z"
      fill="currentColor"
    />
    <path
      d="M6.02433 27.5354C6.02433 27.9238 6.18236 28.2963 6.46367 28.571C6.74497 28.8457 7.1265 29 7.52433 29H22.5243C22.9222 29 23.3037 28.8457 23.585 28.571C23.8663 28.2963 24.0243 27.9238 24.0243 27.5354V24.899H6.02433V27.5354Z"
      fill="currentColor"
    />
  </svg>
</template>
