
import { DateTime } from 'luxon';
import { defineComponent, ref, toRefs, watch } from 'vue';
import { CheckIcon } from 'heroicons-vue3/solid';

export default defineComponent({
  components: {
    CheckIcon,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    done: {
      type: Boolean,
      default: false,
    },
    date: {
      type: Date,
      required: true,
    },
    dateClass: {
      type: String,
      default: '',
    },
    href: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const monthName = ref('');
    const year = ref('');

    watch(
      toRefs(props).date,
      date => {
        const d = DateTime.fromJSDate(date);
        monthName.value = d.monthLong;
        year.value = d.year + '';
      },
      { immediate: true },
    );

    return {
      monthName,
      year,
    };
  },
});
