
import StatsGalleryLogo from '@/components/icons/StatsGalleryLogo.vue';
import { useNear } from '@/composables/useNear';
import { defineComponent, ref, watch } from 'vue';
import SmallNetworkInput from '../form/SmallNetworkInput.vue';
import SmallPrimaryButton from '../form/SmallPrimaryButton.vue';
import SmallTextInput from '../form/SmallTextInput.vue';
import SmallTimeframeInput from '../form/SmallTimeframeInput.vue';
import UserMenu from './UserMenu.vue';

export default defineComponent({
  components: {
    SmallTextInput,
    SmallNetworkInput,
    SmallTimeframeInput,
    SmallPrimaryButton,
    StatsGalleryLogo,
    UserMenu,
  },
  props: {
    showIntake: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { account, network, timeframe } = useNear();

    const displayedAccount = ref(account.value);
    const selectedNetwork = ref(network.value);
    const selectedTimeframe = ref(timeframe.value);

    watch(account, newAccount => {
      displayedAccount.value = newAccount;
    });

    const update = () => {
      if (displayedAccount.value.trim().length === 0) {
        return;
      }

      account.value = displayedAccount.value.toLowerCase();
      network.value = selectedNetwork.value;
      timeframe.value = selectedTimeframe.value;
    };

    const theme = ref(false);

    const bannerImagePath = process.env['VUE_APP_BANNER_IMAGE_PATH'];
    const bannerHref = process.env['VUE_APP_BANNER_HREF'];

    return {
      displayedAccount,
      selectedNetwork,
      selectedTimeframe,
      update,
      theme,
      bannerImagePath,
      bannerHref,
    };
  },
});
