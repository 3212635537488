<template>
  <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.5624 0H2.18742C0.981216 0 0 0.981216 0 2.18742V7.81242C0 9.01886 0.981216 10.0001 2.18742 10.0001H11.5624C12.7689 10.0001 13.7501 9.01886 13.7501 7.81242V2.18742C13.7501 0.981216 12.7689 0 11.5624 0Z"
      fill="currentColor"
    />
    <path
      d="M11.5624 12.4999H2.18742C0.981216 12.4999 0 13.4811 0 14.6876V27.8126C0 29.0188 0.981216 30 2.18742 30H11.5624C12.7689 30 13.7501 29.0188 13.7501 27.8126V14.6876C13.7501 13.4811 12.7689 12.4999 11.5624 12.4999Z"
      fill="currentColor"
    />
    <path
      d="M27.8125 19.9999H18.4375C17.2311 19.9999 16.2499 20.9811 16.2499 22.1876V27.8126C16.2499 29.0188 17.2311 30 18.4375 30H27.8125C29.0188 30 30 29.0188 30 27.8126V22.1876C30 20.9811 29.0188 19.9999 27.8125 19.9999Z"
      fill="currentColor"
    />
    <path
      d="M27.8125 0H18.4375C17.2311 0 16.2499 0.981216 16.2499 2.18742V15.3124C16.2499 16.5189 17.2311 17.5001 18.4375 17.5001H27.8125C29.0188 17.5001 30 16.5189 30 15.3124V2.18742C30 0.981216 29.0188 0 27.8125 0Z"
      fill="currentColor"
    />
  </svg>
</template>
