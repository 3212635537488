<template>
  <svg
    fill="none"
    version="1.1"
    viewBox="0 0 48 48"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m24 0c-13.255 0-24 10.745-24 24s10.745 24 24 24 24-10.745 24-24-10.745-24-24-24zm6.0723 13c0.32442-3.22e-4 0.64928 0.025853 0.97266 0.080078 1.2934 0.2169 2.4808 0.86389 3.377 1.8379 1.3325-0.2663 2.6097-0.7647 3.7773-1.4707-0.4433 1.4036-1.3725 2.5965-2.6152 3.3535 1.2044-0.1469 2.3495-0.47202 3.416-0.95312l-0.001953 0.003906c-0.7983 1.2156-1.8029 2.2759-2.9668 3.1328 0.0115 0.26 0.017578 0.5231 0.017578 0.7832 0 8.0041-5.9842 17.232-16.93 17.232-3.2334 0.0037-6.3993-0.9404-9.1191-2.7207 0.4714 0.0565 0.94532 0.084084 1.4199 0.083984 2.6795 4e-3 5.283-0.90945 7.3906-2.5938-1.2415-0.0231-2.4447-0.44131-3.4414-1.1953-0.9967-0.7539-1.7372-1.8064-2.1172-3.0098 0.892 0.173 1.811 0.13798 2.6875-0.10352-1.3468-0.277-2.5577-1.0209-3.4277-2.1035s-1.3458-2.4372-1.3457-3.8359v-0.078125c0.8262 0.4675 1.7505 0.72721 2.6953 0.75781-0.8147-0.5539-1.4836-1.304-1.9453-2.1836-0.4616-0.8796-0.70177-1.8622-0.70117-2.8594 0-1.1078 0.29389-2.1478 0.80469-3.0449 1.4942 1.8712 3.358 3.4017 5.4707 4.4922 2.1128 1.0904 4.4272 1.717 6.793 1.8379-0.2995-1.2992-0.16871-2.6628 0.37109-3.8789 0.5397-1.2162 1.4576-2.2178 2.6133-2.8477 0.86678-0.47242 1.8314-0.71583 2.8047-0.7168z"
      fill="currentColor"
    />
  </svg>
</template>
