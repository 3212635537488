<template>
  <svg
    fill="none"
    version="1.1"
    viewBox="0 0 48 48"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m24 0c-13.255 0-24 10.745-24 24s10.745 24 24 24 24-10.745 24-24-10.745-24-24-24zm12.812 12.191c0.88583-9.28e-4 1.515 0.75211 1.2012 2.2227l0.001953-0.001953-4.3711 20.688c-0.324 1.4667-1.1924 1.8238-2.4062 1.1328l-6.6582-4.9297-3.2109 3.1074c-0.3551 0.3566-0.6539 0.6582-1.3418 0.6582l0.47266-6.8047 12.338-11.197c0.537-0.4748-0.12012-0.74203-0.82812-0.26953l-15.248 9.6426-6.5723-2.0586c-1.4269-0.4547-1.459-1.434 0.30078-2.125l25.678-9.9434c0.22344-0.081094 0.44011-0.12088 0.64453-0.12109z"
      fill="currentColor"
    />
  </svg>
</template>
