
import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue';
import { XIcon } from 'heroicons-vue3/outline';
import { defineComponent, PropType } from 'vue';
import AutoTeleportRoot from './AutoTeleportRoot.vue';

export default defineComponent({
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    XIcon,
    AutoTeleportRoot,
  },
  props: {
    open: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    prose: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  emits: ['close'],
});
