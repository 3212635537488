<template>
  <svg
    fill="none"
    version="1.1"
    viewBox="0 0 37 41"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m19.809 0.36264c-0.8486-0.48352-1.8894-0.48352-2.7381-1e-6l-15.436 8.7949c-0.86336 0.4919-1.3964 1.4092-1.3964 2.4029v17.527c0 0.9937 0.53309 1.911 1.3964 2.4029l15.436 8.7949c0.8487 0.4835 1.8895 0.4835 2.7381 0l15.436-8.7949c0.8634-0.4919 1.3965-1.4092 1.3965-2.4029v-17.527c0-0.9937-0.5331-1.911-1.3965-2.4029l-15.436-8.7949zm-1.3187 19.621 7.7425-4.46-7.7425-4.462-7.7426 4.462 7.7426 4.46zm-0.5539 0.9576-7.7426-4.4599v8.9236l7.7426 4.462v-8.9257zm1.1079 8.9257v-8.9257l7.7424-4.4599v8.9236l-7.7424 4.462z"
      clip-rule="evenodd"
      fill="currentColor"
      fill-rule="evenodd"
    />
  </svg>
</template>
