<template>
  <svg
    fill="none"
    viewBox="0 0 17.588 17.588"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m8.794 16.588c-1.08 0-2.094-0.204-3.042-0.612-0.936-0.396-1.764-0.948-2.484-1.656-0.708-0.72-1.266-1.548-1.674-2.484-0.396-0.948-0.594-1.962-0.594-3.042s0.198-2.088 0.594-3.024c0.408-0.948 0.966-1.776 1.674-2.484 0.72-0.72 1.548-1.278 2.484-1.674 0.948-0.408 1.962-0.612 3.042-0.612s2.088 0.204 3.024 0.612c0.948 0.396 1.776 0.954 2.484 1.674 0.72 0.708 1.278 1.536 1.674 2.484 0.408 0.936 0.612 1.944 0.612 3.024s-0.204 2.094-0.612 3.042c-0.396 0.936-0.954 1.764-1.674 2.484-0.708 0.708-1.536 1.26-2.484 1.656-0.936 0.408-1.944 0.612-3.024 0.612zm0-1.602c0.864 0 1.668-0.156 2.412-0.468 0.744-0.324 1.398-0.768 1.962-1.332 0.576-0.576 1.02-1.236 1.332-1.98 0.324-0.744 0.486-1.548 0.486-2.412s-0.162-1.668-0.486-2.412c-0.312-0.744-0.756-1.398-1.332-1.962-0.564-0.576-1.218-1.02-1.962-1.332-0.744-0.324-1.548-0.486-2.412-0.486s-1.668 0.162-2.412 0.486c-0.744 0.312-1.404 0.756-1.98 1.332-0.564 0.564-1.008 1.218-1.332 1.962-0.312 0.744-0.468 1.548-0.468 2.412s0.156 1.668 0.468 2.412c0.324 0.744 0.768 1.404 1.332 1.98 0.576 0.564 1.236 1.008 1.98 1.332 0.744 0.312 1.548 0.468 2.412 0.468zm-3.24-2.574v-7.254h2.016l3.024 5.292c0-0.252-6e-3 -0.522-0.018-0.81-0.012-0.3-0.018-0.564-0.018-0.792v-3.69h1.476v7.254h-2.016l-3.06-5.364c0.012 0.24 0.024 0.516 0.036 0.828 0.024 0.312 0.036 0.594 0.036 0.846v3.69z"
      fill="currentColor"
    />
  </svg>
</template>
