
import { BadgeGroup } from '@/composables/badges/badges';
import { Component, defineComponent } from 'vue';
import ContractBadgeVue from './ContractBadge.vue';
import NftBadgeVue from './NftBadge.vue';
import StakeBadgeVue from './StakeBadge.vue';
import TransactionBadgeVue from './TransactionBadge.vue';

export default defineComponent({
  props: {
    group: {
      type: String as () => BadgeGroup,
      required: true,
    },
    gildClass: {
      type: String,
      default: '',
    },
  },
  setup() {
    const icon: Record<BadgeGroup, Component> = {
      nft: NftBadgeVue,
      transfer: TransactionBadgeVue,
      contract: ContractBadgeVue,
      stake: StakeBadgeVue,
    };

    const iconColor: Record<BadgeGroup, string> = {
      nft: '#3B82F6',
      transfer: '#EF5DA8',
      contract: '#059669',
      stake: '#0369A1',
    };

    return {
      icon,
      iconColor,
    };
  },
});
