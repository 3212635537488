
import { defineComponent, Component } from 'vue';

export default defineComponent({
  props: {
    href: {
      type: String,
      required: true,
    },
    icon: {
      type: [Object, Function],
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    external: {
      type: Boolean,
      default: false,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
  },
});
