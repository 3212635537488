
import { Timeframe } from '@/utils/timeframe';
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from '@headlessui/vue';
import { CheckIcon, SelectorIcon } from 'heroicons-vue3/outline';
import { defineComponent, ref, watch } from 'vue';

export default defineComponent({
  components: {
    Listbox,
    ListboxButton,
    ListboxOption,
    ListboxOptions,
    CheckIcon,
    SelectorIcon,
  },
  props: {
    modelValue: {
      type: String as () => Timeframe,
      required: true,
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const timeframes = [
      {
        text: 'day',
        value: Timeframe.DAY,
      },
      {
        text: 'week',
        value: Timeframe.WEEK,
      },
      {
        text: 'month',
        value: Timeframe.MONTH,
      },
      {
        text: 'year',
        value: Timeframe.YEAR,
      },
      {
        text: 'all',
        value: Timeframe.ALL,
      },
    ];

    const fallbackTimeframe = timeframes[1];

    const findTimeframe = (search: Timeframe) =>
      timeframes.find(t => t.value === search) ?? fallbackTimeframe;

    const selectedItem = ref(findTimeframe(props.modelValue));

    watch(selectedItem, selectedItem => {
      emit('update:modelValue', selectedItem.value);
    });

    return {
      timeframes,
      selectedItem,
    };
  },
});
