
import Chart from '@/components/Chart.vue';
import NetworkInput from '@/components/form/NetworkInput.vue';
import PrimaryButton from '@/components/form/PrimaryButton.vue';
import TextInput from '@/components/form/TextInput.vue';
import TimeframeInput from '@/components/form/TimeframeInput.vue';
import { useNetworkActivityChart } from '@/composables/charts/useNetworkActivityChart';
import { useMultiple } from '@/composables/useMultiple';
import { useSingle } from '@/composables/useSingle';
import { Network } from '@/services/near/indexer/networks';
import {
  CachedAccountRecord,
  NearWeekCachedStats,
} from '@/services/near/indexer/types';
import { Timeframe } from '@/utils/timeframe';
import { defineComponent, ref, watch } from 'vue';
import { useRouter } from 'vue-router';

export default defineComponent({
  components: {
    TextInput,
    TimeframeInput,
    NetworkInput,
    PrimaryButton,
    Chart,
  },
  name: 'intake-hero',
  setup() {
    const accountInputValue = ref('');
    const timeframeInputValue = ref<Timeframe>(Timeframe.WEEK);
    const networkInputValue = ref<Network>(Network.MAINNET);

    const { value: allAccounts } = useSingle(
      'all-accounts',
      {
        account: '',
        network: networkInputValue,
        timeframe: Timeframe.ALL,
      },
      314071,
    );

    type NewAccountsByDateEntry = {
      new_accounts: number;
      block_date: string;
    };
    const { value: newAccountsByDate } = useMultiple<NewAccountsByDateEntry>(
      'new-accounts-count',
      { network: networkInputValue },
    );
    const chartOption = useNetworkActivityChart(newAccountsByDate);

    type NewAccountEntry = {
      account_id: string;
      block_timestamp: number;
    };

    const { value: newAccounts } = useMultiple<NewAccountEntry>(
      'new-accounts-list',
      {
        network: networkInputValue,
      },
    );

    const { value: balanceLeaderboard } = useMultiple<CachedAccountRecord>(
      'leaderboard-balance',
      {
        account: '',
        network: Network.MAINNET,
        timeframe: Timeframe.ALL,
      },
      [],
    );

    const { value: scoreLeaderboard } = useMultiple<CachedAccountRecord>(
      'leaderboard-score',
      {
        account: '',
        network: Network.MAINNET,
        timeframe: Timeframe.ALL,
      },
      [],
    );

    const { value: transactionsLeaderboard } = useMultiple<NearWeekCachedStats>(
      'leaderboard-transactions-week',
      {
        account: '',
        network: Network.MAINNET,
        timeframe: Timeframe.WEEK,
      },
      [],
    );

    const { value: weeklyDappsLeaderboard } = useMultiple<NearWeekCachedStats>(
      'leaderboard-dapps-week',
      {
        account: '',
        network: Network.MAINNET,
        timeframe: Timeframe.WEEK,
      },
      [],
    );

    const { value: weeklyTopLeaderboard } = useMultiple<NearWeekCachedStats>(
      'leaderboard-nfts-week',
      {
        account: '',
        network: Network.MAINNET,
        timeframe: Timeframe.WEEK,
      },
      [],
    );

    const accountsJumble = ref<string[]>([]);

    watch(
      [
        newAccounts,
        scoreLeaderboard,
        balanceLeaderboard,
        transactionsLeaderboard,
        weeklyDappsLeaderboard,
        weeklyTopLeaderboard,
      ],
      ([
        newAccounts,
        scoreLeaderboard,
        balanceLeaderboard,
        transactionsLeaderboard,
        weeklyDappsLeaderboard,
        weeklyTopLeaderboard,
      ]) => {
        const a = [];
        for (let i = 0; i < 10; i++) {
          const randomArray = [
            newAccounts,
            scoreLeaderboard,
            balanceLeaderboard,
          ][Math.floor(Math.random() * 3)];
          if (randomArray.length) {
            const randomSelection =
              randomArray[Math.floor(Math.random() * randomArray.length)]
                .account_id;
            a.push(randomSelection);
          }
        }

        accountsJumble.value = Array.from(new Set(a));
      },
    );

    const router = useRouter();

    const go = () => {
      router.push({
        name: 'overview',
        params: {
          network: networkInputValue.value,
          account: accountInputValue.value,
        },
        query: {
          t: timeframeInputValue.value,
        },
      });
    };

    return {
      accountInputValue,
      timeframeInputValue,
      networkInputValue,
      chartOption,
      go,
      allAccounts,
      accountsJumble,
    };
  },
});
